import socketIOClient from 'socket.io-client'

export const REQUEST_SOCKET = 'REQUEST_SOCKET'
export const RECEIVE_SOCKET = 'RECEIVE_SOCKET'
export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET'
export const ERROR_SOCKET = 'ERROR_SOCKET'
export const RECEIVE_LATEST_STATS = 'RECEIVE_LATEST_STATS'
export const RECEIVE_BLOCKHEADER = 'RECEIVE_BLOCK_HEADER'

export function fetchSocket() {
  return dispatch => {
    dispatch({ type: REQUEST_SOCKET })
    const socket = socketIOClient(process.env.REACT_APP_END_POINT, { transports: ['websocket']})
    socket.on('connect', () => dispatch({ type: RECEIVE_SOCKET, payload: socket }))
    socket.on('disconnect', () => dispatch({ type: DISCONNECT_SOCKET }))
    socket.on('ethHeader', header => dispatch({ type: RECEIVE_BLOCKHEADER, payload: header }))
    socket.on('ck-floorUpdate', totals => dispatch({ type: RECEIVE_LATEST_STATS, payload: totals }))
    socket.on("connect_error", (err) => console.log(`connect_error due to ${err.message}`))
  }
}
