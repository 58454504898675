import styled from 'styled-components'

export const Section = styled.section`
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 700px) {
    width: 96%;
    margin: 0 2%;
  }
  > div {
    width: 100%;
    @media (min-width: 1000px) {
      width: 30%;
      &:first-of-type {
        width: 68%;
        margin-right: 2%;
      }
    }
    position: relative;
  }
  h3 {
    margin-bottom: 24px;
    font-size: 24px;
  }
  > div > h2 {
    font-size: 36px;
    margin-bottom: 36px;
  }
`

export const Div = styled.div`
  display: flex;
  width: 96%;
  margin: 40px 2%;
  flex-direction: column;
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
  }
  h3 {
    margin-bottom: 20px;
    &.columnHeading {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  h4 {
    padding: 4px 2%;
    a {
      color: rgb(239,82,209);
    }
  }
  p {
    a {
      color: rgb(239,82,209);
    }
    b {
      font-weight: bold;
    }
  }
`

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  @media (min-width: 800px) {
    flex-direction: row;
    &:nth-of-type(2) {
      width: 60%;
    }
  }
  &:last-of-type {
    display: none;
    @media (min-width: 1180px) {
      display: flex;
      width: 40%;
    }
  }
`

export const SubColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 800px) {
    width: 60%;
    &:last-of-type {
      width: 40%;
    }
  }
`
