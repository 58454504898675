import {
  REQUEST_SOCKET,
  RECEIVE_SOCKET,
  ERROR_SOCKET,
  RECEIVE_LATEST_STATS,
  RECEIVE_BLOCKHEADER
} from './actions'

const initialState = {
  socket: false,
  latestStats: undefined,
  blockHeader: undefined,
}

const kittiesReducer = function kittiesReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_SOCKET:
      return {
        ...state
      }
    case RECEIVE_SOCKET:
      return {
        ...state,
        socket: action.payload
      }
    case ERROR_SOCKET:
      return {
        ...state,
        error: action.payload
      }

    case RECEIVE_LATEST_STATS:
      return {
        ...state,
        latestStats: action.payload
      }

    case RECEIVE_BLOCKHEADER:
      return {
        ...state,
        blockHeader: action.payload
      }

    default:
      return state
  }
}

export default kittiesReducer
