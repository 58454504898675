import styled from 'styled-components'
import loader from 'img/loader.gif'

export const Div = styled.div`
  width: 100%;
  background-image: url('${loader}');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
`
