import * as Styled from './Pagination.style'

const Pagination = ({ handlePageClick, pageCount }) => {
    return (
        <Styled.Div
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
        />
    )
}

export default Pagination