import React from 'react'
import { Link } from 'react-router-dom'
import Mewtations from './Mewtations'
import FamilyJewels from './FamilyJewels'
import { handleGetCoolDown, handleGetBirthday, formatPrice, genes, getCurrentPrice, getTimestamp } from 'utils'
import * as Styled from './Kitty.style'
import bug from 'svg/bug.svg'

const displayPrice = (id, auction, handlePurchase) => {
  const { current_price, type, seller } = auction
  return type === 'sire'
    ? <span className={'broken'}>
        {'🍆' } <span></span>{formatPrice(current_price,3)}
      </span>
    : <span className={seller.isDapper ? 'broken' : 'hover'} onClick={() => handlePurchase(id, current_price)}>
        {'🛒'} <span></span>{formatPrice(current_price,3)}
      </span>
}

const Kitty = ({ kitty, sold, sired, handlePurchase, handleSire }) => {
  const now = getTimestamp()
  return kitty.tokenId ? (
    <Styled.Div>
      <div className={genes[kitty.g8].ec}>
        <img 
          src={`https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.png`}
          onError={(e) => { e.target.onerror = null; e.target.src = `https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.svg` }}
          alt={'Kitty #'+kitty.tokenId}
        />
        {kitty.active &&
          <span style={{ pointer: 'cursor' }} onClick={() => handlePurchase(
            kitty.tokenId,
            getCurrentPrice(kitty.startingPrice, kitty.endingPrice, kitty.endTime.toString(),now)
          )}>🛒 <span></span>{formatPrice(kitty.currentPrice)}</span>
        }
        {kitty && kitty.success && 
          <span>SOLD: <span></span>{formatPrice(kitty.totalPrice,3)}</span>
        }
        {sired &&
          <span>SIRE PRICE <span></span>{formatPrice(kitty.price,1)}</span>
        }
        {kitty.auction && kitty.auction.type && !sold &&
          displayPrice(kitty.id, kitty.auction, handlePurchase)
        }
        {/* <Mewtations kitty={kitty} />
        <FamilyJewels kitty={kitty} /> */}
      </div> 
      <h2>
        <Link to={{ pathname: `https://www.cryptokitties.co/kitty/${kitty.tokenId}`}} target="_blank">#{kitty.tokenId}</Link>
      </h2>
      <h4>
        <span>Gen{kitty.gen} - {handleGetCoolDown(kitty.gen, kitty.offspring)}</span>
        <span>{kitty.success
          ? handleGetBirthday(kitty.hatched.timestamp * 1000)
          : handleGetBirthday(kitty.timestamp * 1000)}
        </span>
        <span>Owners: {kitty.owners.length} - Offspring: {kitty.offspring}</span>
      </h4>
    </Styled.Div>
  ) : (
    <Styled.Div>
      <div className={kitty.color}>
        <img 
          src={`https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.id}.svg`}
          onError={(e) => { e.target.onerror = null; e.target.src = `https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.id}.png` }}
          alt={'Kitty #'+kitty.id}
        />
        {kitty.auction && kitty.auction.type === 'sale' &&
          <span style={{ pointer: 'cursor' }} onClick={() => handlePurchase(
            kitty.id,
            getCurrentPrice(kitty.auction.start_price, kitty.auction.end_price,kitty.auction.start_time, kitty.auction.end_time, now)
          )}>🛒 <span></span>{formatPrice(kitty.auction.current_price)}</span>
        }
        {/* {kitty && kitty.success && 
          <span>SOLD: <span></span>{formatPrice(kitty.totalPrice,3)}</span>
        }
        {sired &&
          <span>SIRE PRICE <span></span>{formatPrice(kitty.price,1)}</span>
        }
        {kitty.auction && kitty.auction.type && !sold &&
          displayPrice(kitty.id, kitty.auction, handlePurchase)
        } */}
        {/* <Mewtations kitty={kitty} />
        <FamilyJewels kitty={kitty} /> */}
      </div> 
      <h2>
        <Link to={{ pathname: `https://www.cryptokitties.co/kitty/${kitty.id}`}} target="_blank">#{kitty.id}</Link>
      </h2>
      <h4>
        <span>Gen{kitty.generation /* } - {handleGetCoolDown(kitty.gen, kitty.offspring) */}</span>
        {/* <span>{kitty.success
          ? handleGetBirthday(kitty.hatched.timestamp * 1000)
          : handleGetBirthday(kitty.timestamp * 1000)}
        </span> */}
        {/* <span>Owners: {kitty.owners.length} - Offspring: {kitty.offspring}</span> */}
      </h4>
    </Styled.Div>
  )

}
  

export default Kitty
