import styled from 'styled-components'

export const Main = styled.main`
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  table {
    margin: 40px 0 80px;
    width: 100%;
    border: 1px solid #000;
    font-size: 12px;
    tr {
      &:first-of-type {
        border-bottom: 1px solid;
        td {
          font-weight: bold;
        }
      }
    }
    tr {
      &:nth-of-type(2) {
        td {
          background-color: #EEE;
          font-weight: bold;
          > span {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
    td {
      text-align: left;
      padding: 10px 0 10px 12px;
      > b {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  h3 {
    width: 100%;
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
    color: #404040;
  }
  > ul {
    display: flex;
    margin-bottom: 40px;
    li {
      list-style-type: none;
      margin-right: 12px;
      cursor: pointer;
      &.disabled {
        > a {
          text-decoration: none;
          cursor: default;
          opacity: 0.4;
        }
      }
      &.selected {
        > a {
          text-decoration: none;
          cursor: default;
          color: #c23da8;
        }
      }
    }
  }
`


export const KeyDiv = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    > span {
      display: inline-block;
      width: 40px;
      height: 2px;
      margin-right: 6px;
    }
    &:nth-of-type(1) {
      > span {
        background: ${({ color1 }) => color1};
      }
    }
    &:nth-of-type(2) {
      > span {
        background: ${({ color2 }) => color2};
      }
    }
    &:nth-of-type(3) {
      > span {
        background: ${({ color3 }) => color3};
      }
    }
    &:nth-of-type(4) {
      > span {
        background: ${({ color4 }) => color4};
      }
    }

  }
`
