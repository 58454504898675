import React from 'react'
import { Link } from 'react-router-dom'
import * as Styled from './Story.style'

const Story = ({ date, title, img, imgWidth,  url, extUrl, copy, c2a, smaller }) => {
  return (
    <Styled.Div {...{ smaller }}>
      {extUrl
       ?  <>
            <div>
              <h2>{title}</h2>
              <pre>- {date}</pre>
              {copy && <p dangerouslySetInnerHTML={{ __html: copy}} />}
              {c2a && <p><a href={c2a} target={'_blank'}>Find out more here!</a></p>}
            </div>
            <div>
              <img src={img} alt={title} />
            </div>
          </>
       : <>
          <div>
            <h2>{title}</h2>
            <pre>- {date}</pre>
            {copy && <p dangerouslySetInnerHTML={{ __html: copy}} />}
            {c2a && <p><a href={c2a} target={'_blank'}>Find out more here!</a></p>}
          </div>
          <div>{img ?
              extUrl || url
              ? <Link to={url}>
                  <img src={img} alt={title} style={{ width: imgWidth ? imgWidth : '100%' }} />
                  <h2>{title}</h2>
                </Link>
              : <>
                  <img src={img} alt={title} style={{ width: imgWidth ? imgWidth : '100%' }} />
                  <h2>{title}</h2>
                </>
            : <h2>{title}</h2>
          }
          </div>
         </>
      }
    </Styled.Div>
  )
}

export default Story
