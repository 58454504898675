import Kitty from 'components/Kitty'
import { formatDate } from 'utils'
import * as Styled from './Latest.style'

const formatAddress = (address) => `${address.substring(0, 10)}..`

const Latest = ({ kitty, title, handlePurchase, handleSire }) =>
  <Styled.Div>
    <h3>{title}</h3>
    {kitty.success &&
      <>
        <p>{formatDate(kitty.ended)}</p>
        <p><b>New Owner:</b> {formatAddress(kitty.winner)} <b>Seller:</b> {formatAddress(kitty.owner)}</p>
      </>
    }
    <Kitty {...{ kitty, handlePurchase, handleSire }} />
  </Styled.Div>

export default Latest
