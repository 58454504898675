import styled from "styled-components"

const colorsAlternate = {
  autumnmoon: '#fdf3e0',
  babypuke: '#eff1e0',
  bridesmaid: '#ffd5e5',
  bubblegum: '#fadff4',
  chestnut: '#efe1da',
  coralsunrise: '#fde9e4',
  cyan: '#c5eefa',
  dahlia: '#e6eafd',
  doridnudibranch: '#faeefa',
  downbythebay: '#cde5d1',
  eclipse: '#e5e7ef',
  isotope: '#effdca',
  forgetmenot: '#dcebfc',
  gold: '#faf4cf',
  limegreen: '#d9f5cb',
  mintgreen: '#cdf5d4',
  oasis: '#e6faf3',
  olive: '#ecf4e0',
  palejade: '#e7f1ed',
  parakeet: '#e5f3e2',
  pinefresh: '#dbf0d0',
  pumpkin: '#fae1ca',
  sapphire: '#d3e8ff',
  sizzurp: '#dfdffa',
  strawberry: '#ffe0e5',
  thundergrey: '#eee9e8',
  topaz: '#d1eeeb',
  twilightsparkle: '#ede2f5',
};

export const Div = styled.div`
  --defaultAnimationTime: 0.15s;
  --kaleidoscopeLoopDuration: 4s;
  --kaleidoscopeDelay: 0.1s;
  > div {
    position: relative;
    width: 90%;
    margin-left: 5%;
    position: relative;
    box-sizing: border-box;
    border-radius: 6px;
    > span {
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 14px;
      font-weight: bold;
      background-color: #FFF;
      padding: 3px 5px;
      border-radius: 3px;
      cursor: default;
    }
    > img {
      width: 100%;
      position: relative;
      &:nth-of-type(2) {
        z-index: 1;
        margin-left: -13.5%;
        width: 12.5%;
        top: 1%;
      }
    }
    &.kaleidoscope {
      animation: kaleidoscopeBg var(--kaleidoscopeLoopDuration) linear infinite;
      will-change: background-color;
    }
    .thundergrey, .ec1 { background-color: #eee9e8; }
    .gold, .ec2 { background-color: #faf4cf; }
    .topaz, .ec3 { background-color: #d1eeeb; }
    .mintgreen, .ec4 { background-color: #cdf5d4; }
    .isotope, .ec5 { background-color: #effdca; }
    .sizzurp, .ec6 { background-color: #dfdffa; }
    .chestnut, .ec7 { background-color: #efe1da; }
    .strawberry, .ec8 { background-color: #ffe0e5; }
    .sapphire, .ec9 { background-color: #d3e8ff; }
    .forgetmenot, .eca { background-color: #dcebfc; }
    .dahlia, .ecb { background-color: #e6eafd; }
    .coralsunrise, .ecc { background-color: #fde9e4; }
    .olive, .ecd { background-color: #ecf4e0; }
    .doridnudibranch, .ece { background-color: #faeefa; }
    .parakeet, .ecf { background-color: #e5f3e2; }
    .cyan, .ecg { background-color: #c5eefa; }
    .pumpkin, .ech { background-color: #fae1ca; }
    .limegreen, .eci { background-color: #d9f5cb; }
    .bridesmaid, .ecj { background-color: #ffd5e5; }
    .bubblegum, .eck { background-color: #fadff4; }
    .twilightsparkle, .ecm { background-color: #ede2f5; }
    .palejade, .ecn { background-color: #e7f1ed; }
    .pinefresh, .eco { background-color: #dbf0d0; }
    .eclipse, .ecp { background-color: #e5e7ef; }
    .babypuke, .ecq { background-color: #eff1e0; }
    .downbythebay, .ecr { background-color: #cde5d1; }
    .autumnmoon, .ecs { background-color: #fdf3e0; }
    .oasis, .ect { background-color: #e6faf3; }
    .gemini, .ecu { background-color: #000; }
    .dioscuri, .ecv { background-color: #d1eeeb; }
  }

  @keyframes kaleidoscopeBg {
    0% {
      background-color: ${colorsAlternate.strawberry};
    }
    20% {
      background-color: ${colorsAlternate.pumpkin};
    }
    40% {
      background-color: ${colorsAlternate.gold};
    }
    60% {
      background-color: ${colorsAlternate.limegreen};
    }
    80% {
      background-color: ${colorsAlternate.cyan};
    }
    100% {
      background-color: ${colorsAlternate.strawberry};
    }
  }

  h2 {
    line-height: 40px;
    span {
      margin-left: 6px;
      font-size: 14px;
    }
  }

  h4 {
    margin: 0;
    font-size: 14px;
    line-height: 16px;
    a {
      color: #666;
    }
    span {
      display: block;
      > span {
        display: inline-block;
        margin-right: 2px;
      }
    }
  }
  .hover {
    cursor: pointer;
  }
  .broken {
    opacity: 0.4;
  }


`
