import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Loading from 'components/Loading'
import Kitty from 'components/Kitty'
import ChartContainer from 'components/ChartContainer'
import Pagination from 'components/Pagination'
import { API_URL } from 'utils'
import * as Styled from './Markets.style'

const Markets = ({
  match: { params: { report_type, page } },
  socket: { latestStats },
  handlePurchase, handleSire
}) => {

  const history = useHistory()

  const [kitties, setKitties] = useState(undefined)
  const [floor, setFloor] = useState(undefined)
  const [total, setTotal] = useState(undefined)
  const [floorTotal, setFloorTotal] = useState(undefined)

  useEffect(() => {
    if (page) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [page])

  useEffect(() => {
    setKitties(undefined)
    setFloor(undefined)
    setTotal(undefined)
    setFloorTotal(undefined)
    const apiUrlSales = report_type === 'sires' ? `${API_URL}/sires` : `${API_URL}/sales`
    const apiUrlFloor =  report_type === 'sires' ? `${API_URL}/sires/floor` : `${API_URL}/sales/floor`
    let query = ''
    let _total
    if (report_type === 'founders') {
      query = '?id=[1-100]'
      _total = 100
    }
    if (report_type === 'gen0') {
      query = '?gen=0'
      _total = latestStats?.gen0.total
    }
    if (report_type === 'day1') {
      query = '?id=[1-3365]'
      _total = 3365
    }
    if (report_type === '2017') {
      query = '?id=[1-438354]'
      _total = 438354
    }
    axios.get(apiUrlSales + query).then((response) => {
      setKitties([...response.data.reverse()])
      axios.get(apiUrlFloor + query).then(response => {
        setFloor(response.data)
        setTotal(_total)
        setFloorTotal(_total)
      })
    })
  }, [report_type])

  return (
      <Styled.Main>
        {kitties && (report_type === 'gen0' || report_type === 'day1' || report_type === '2017' || report_type === 'founders' || report_type === 'sales'  || report_type === 'sires') &&
          <ChartContainer title={`${report_type} - Recent ${report_type === 'sires' ? 'Sires' : 'Sales'}`} {...{ kitties }}  />
        }
        <h3>{`${report_type} Floor - Total: ${floorTotal ? floorTotal.toLocaleString() : '..'}`}</h3>
        {!floor
          ? <Loading />
          : <>
              <Styled.Div>
                {floor.map((kitty, key) =>
                  <Kitty {...{ kitty, key, handlePurchase, handleSire }} />
                )}
              </Styled.Div>
              <Pagination handlePageClick={({ selected }) => {
                selected === 0
                  ? history.push(history.location.pathname)
                  : history.push(`?page=${selected + 1}`)
              }} pageCount={Math.ceil(floorTotal / 24)} 
              />
            </>
        }
      </Styled.Main>
  )
}

const mapStateToProps = ({ socket }) => ({ socket })
const connected = connect(mapStateToProps, null)(Markets)
export default connected
