import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchSocket } from 'App/actions'
import axios from 'axios'
import { Link } from 'react-router-dom'
import siringRestored from 'img/siring-restored.jpg'
import cyberbrokers from 'img/cyberbrokers.jpg'
import leonidas from 'img/leonidas.png'
import cataloguev2 from 'img/catalogue.png'
import breeding from 'img/breeding.png'
import kittycup from 'img/kittycup.jpg'
import tradfilines from 'img/tradfilines.png'
import wlk from 'img/we-love-kitties.jpeg'
import futureMeow from 'img/futureismeow.jpeg'
import whosyourdaddy from 'img/whosyourdaddy.jpg'
import posGenesis from 'img/posGenesis.png'
import AtAGlance from 'components/AtAGlance'
import Auctions from 'components/Auctions'
import ChartContainer from 'components/ChartContainer'
import Latest from 'components/Latest'
import Yesterday from 'components/Yesterday'
import Story from 'components/Story'
import { API_URL } from 'utils'
import * as Styled from "./Homepage.style"

const Homepage = ({ account, latestKitties, volume, handlePurchase, socket: { latestStats } }) => {
  const [kitties, setKitties] = useState(undefined)
  const [latestFounderSale, setLatestFounderSale] = useState(undefined)
  const [latestGen100, setLatestGen100] = useState(undefined)

  useEffect(() => {
    if (latestStats?.sales?.sales) {
      setKitties([...latestStats.sales.sales.reverse()])
    }
  }, [latestStats])

  useEffect(() => {
    axios.get(`${API_URL}/sales?id=[1-100]`).then(response => {
      if (response.data.length > 0) {
          setLatestFounderSale(response.data)
        }
    })
  }, [])

  useEffect(() => {
    axios.get(`${API_URL}/kitties?gen=[100]`).then(response => {
      if (response.data.length > 0) { 
        setLatestGen100(response.data[0])
      }
    })
  }, [])

 return (
   <>
    <Styled.Section>
      <ChartContainer {...{ kitties }} title={'Latest Sales'} />
      {latestKitties && <Latest kitty={latestKitties[0]} title={'Latest Kitty'} />}
    </Styled.Section>
    <Styled.Section>
      <div>
        <h2>{'Nifty News...'}</h2>
        <Story
          date={'20th Sept 2023'}
          img={whosyourdaddy}
          title={'Whos Your Daddy - CryptoKitties launches new game 🙀'}
          extUrl={'https://cryptokitties.co/game/whos-your-daddy'}
          copy={'To celebrate NFT Day CryptoKitties launched a new free to play multiplayer game where you have to guess the sire of a Kitty in a race against other players. Rounds take place every minute and you don\'t even need kitties to play so its a great way for newcomers to learn about the game.'}
          c2a={'https://cryptokitties.co/game/whos-your-daddy'}
        />
        <Story
          date={'23rd June 2023'}
          img={futureMeow}
          title={'Kitty.Family launches web3 Chatroom'}
          extUrl={'https://kitty.family'}
          copy={'Kitty.Family - now into its 6th year of being a helpful Family Tree display for any CryptoKitty - has a new chatroom that anyone can use provided they sign in with Metamask and own at least 1 kitty.'}
          c2a={'https://kitty.family'}
        />
        <Story
          date={'21st June 2023'}
          img={wlk}
          title={'Benny Giang sends Kitties to all EthWaterloo participants'}
          extUrl={'https://twitter.com/BennyGiang/status/1672002757845295104'}
          copy={'OG CK Dev Benny Giang spoke about EIP6551 at EthWaterloo today which lets NFTs to be sent to each other - opening up whole realms of possibilities for whats possible for web3 moving forward. Using Kitties as an example he even recently sent a Ducat to "The Goose" - and every attendee of EthWaterloo also received a CryptoKitty.'}
          c2a={'https://twitter.com/BennyGiang/status/1672002757845295104'}
        />
        <Story
          date={'6th March 2023'}
          img={tradfilines}
          title={'Kittens Mittens and TradfiLines'}
          extUrl={'https://twitter.com/CryptoKitties/status/1631757736953167874'}
          copy={'The CK team did a Twitter spaces with KittensMittens - holder of over 230,000 CryptoKitties 🙀 and founder of Tradfi Lines - to discuss Bitcoin Ordinals and what its like to have so many cats!'}
          c2a={'https://twitter.com/CryptoKitties/status/1631757736953167874'}
          smaller={true}
        />
        <Story
          date={'12th December 2022'}
          img={kittycup}
          title={'Kitty Cup 2022'}
          extUrl={'https://cryptokitties.co/catalogue'}
          copy={'4 new Fancies (each with 2 variants) were announced today as part of the "Kitty Cup". The variants are all the quater-finalists of the human "World Cup" though but, in a twist, carry the same "teamXXX" name as the Kitty Cup in 2018 - meaning there are new variants such as TeamMorocco in addition to regulars such as TeamBrazil.'}
          c2a={'https://cryptokitties.co/catalogue'}
        />
        <Story
          date={'15th August 2022'}
          img={breeding}
          title={'🎉 Breed fee finally reduced to Ξ0.008 🎉'}
          extUrl={'https://cryptokitties.co/pregnant'}
          smaller={true}
          copy={'The CryptoKitties breeding fee was reduced to Ξ0.008 for the first time in over a year today. Players immediately started breeding with over 100 kitties pregnant within a few hours of the announcement. In other news a new Activity section was launched on each CryptoKitty\'s page showing you every step of their blockchain journey so far.'}
        />
        <Story
          date={'24th April 2022'}
          title={'Siring market reopens 🎉'}
          img={siringRestored}
          extUrl={'https://cryptokitties.co/search?include=sire'}
          copy={'The siring market reopened for Dapper wallet users yesterday having spent just over a year offline. However Metamask or other wallet users cannot due to siring transactions requiring support for EIP2930. Dapper ETH wallet now has this support, while MetaMask projected that this would be "early 2022" but has not been released yet.'}
          c2a={'https://cryptokitties.co/search?include=sire'}
       />
        <Story
          date={'22nd March 2022'}
          title={'CryptoKitties appear in Cyberbrokers'}
          img={cyberbrokers}
          extUrl={'https://cyberbrokers.io/'}
          copy={'Cyberbrokers, created by CryptoKitties OG Josie Bellini, is a series of 10,001 on-chain NFT collectibles that provide membership and access to different kinds of metaversal experiences and features (officially approved) appearances from CryptoKitties such as Miss Matheson and (our personal favourite!) Stitches float balloons. 😻'}
          smaller={true}
          c2a={'https://www.cyberbrokers.com/'}
        />
        <Story
          date={'6th Jan 2021'}
          title={'Kitty 21 adopted by NFT Archeologist'}
          img={leonidas}
          extUrl={'https://cryptokitties.co/kitty/21'}
          copy={'Founder <a href="https://cryptokitties.co/kitty/21">Kitty #21</a> has been adopted by NFT collector and archeologist <a href="https://twitter.com/LeonidasNFT" target="_blank">@Leonidas.eth</a> who champions the cause of historical NFTs and mentions in a <a href="https://twitter.com/LeonidasNFT/status/1478863736773529602">tweet thread about his purchase</a>: <br /><br />"As an NFT Archaeologist I have analyzed nearly every project from 2014-2018 and the 100 founder <a href="https://twitter.com/CryptoKitties" target="_blank">@CryptoKitties</a> are some of the most historically significant artifacts that I have come across."<br /><br />If you are interested in NFT history Leonidas is part of <a href="https://t.co/dwPVaY2cte">a Discord group dedicated to the subject</a> and are curating an exhaustive timeline of the history of NFTs. (Yes - that\'s right kittyheads = there are apparently other NFTs now...)'}
        />
        <Story
          date={'13th Dec 2021'}
          title={'Catalogue v2'}
          img={cataloguev2}
          extUrl={'https://cryptokitties.co/catalogue'}
          copy={'CryptoKitties catalogue gets an overhaul with some exciting new features such as who owns / bred important kitties such and diamond mewts, fancies and founders.<br /><br />Reception to the new catalogue has been very positive with <a href="https://twitter.com/alxocity" target="_blank">@alxo</a> tweeting <i>"New @CryptoKitties Catalogue is the best place to get started on the scarcity within the KittyVerse"</i>'}
        />
       {/* <Story
          date={'1st Dec 2021'}
          title={'PapaCK joins the CK dev team'}
          img={papaCK}
          extUrl={'https://twitter.com/papaNFT'}
          copy={'Kitty Grandmaster and OG discord mod <a href="https://twitter.com/papaNFT" target="_blank">PapaCK</a> has joined the CryptoKitties dev team! Anyone who has played CryptoKitties will know Papa as developer of the indispensable <a href="http://www.ckbox.co/" target="_blank">CKBox plugin</a> in addition to his tireless efforts as a discord mod over the years and now we will be able to benefit from his peerless knowledge of CryptoKitties moving forward...'}
          smaller={true}
        />
        <Story
          date={'9th Sept 2021'}
          title={'Search Engine v2'}
          img={searchv2}
          extUrl={'https://cryptokitties.co/search'}
          copy={'CryptoKitties gets a new search engine powered by KittyHelper and put together by the team at kitty.news. Totals and pagination make a welcome return as well as new features such as a virginity filter, current type / mewtation / cooldown floors, and a "Your Kitties" function that allows you to switch to your own kitties within any active search.'}
        />
        <Story
          date={'13th August 2021'}
          title={'Dev team from KittyHelper join CK'}
          img={kittyHelper}
          copy={'Exclusive: The devs behind <a href="https://kittyhelper.co" target="_blank">KittyHelper</a> are joining the CryptoKitties team. Anyone who has played CryptoKitties will know what a useful (invaluable!) resource <a href="https://kittyhelper.co" target="_blank">KittyHelper</a> has been since it was first launched in 2019 and goes a good way to dispel recent concerns that Dapper Labs "only care about basketball now" with the introduction of a new development team to take CryptoKitties forward through this new, exciting era for NFTs.'}
          smaller={true}
        />
        <Story
          date={'26th July 2021'}
          title={'Cryptokitties: 3d Models'}
          img={models}
          copy={'EXCLUSIVE: a picture was leaked from Dapper Labs recently of official (?) 3d Models of Cryptokitties and what looks like an accompanying certificate displaying the name, traits / family jewels, id, generation and picture of each Kitty.<br /><br />At the moment only Kitty Grandmasters are allowed to apply for a Kitty but (fingers crossed) this will hopefully be rolled out to all users soon. (more as we get it...)'}
          smaller={true}
        />
         <Story
          date={'14th June 2021'}
          title={'Siring Auctions broken by Berlin fork'}
          img={bug}
          imgWidth={'50%'}
          copy={'EXCLUSIVE: Today marks 2 months to the day since the bidOnSiringAuction method on the cK core contract stopped working. The "Berlin fork" Ethereum network upgrade enforced tighter coding standards and this exposed a small bug in the contract that was (and always has been apparently!) sending 2 lustful kitties to another part of the contract with the wrong uint type. :/<br /><br />Other changes introduced by EIP-2929 have caused issues on the Dapper wallets with many functionalities limited until Dapper Labs can roll out a fix for the now mounting issues. Too much time playing basketball maybe? ;) Or failing that can we make an EIP that reverts this change so we can breed kitties again...?!<br /><br />kitty.news now shows all kitties with either the siring auction bug or the dapper wallet bug with the icon above so you immediately know that this kitty is currently unavailable whilst keeping our fingers crossed that a fix is coming soon!'}
       /> 
        <Story
          date={'4th June 2021'}
          title={'Kitty #2000000'}
          img={twomill}
          extUrl={'https://www.cryptokitties.co/kitty/2000000'}
          copy={'Congrats to CryptoKittyMagic who was the lucky breeder to hatch the 2 millionth cryptokitty. It has not been an easy time for breeders with both high gas and eth price, issues with siring auctions and dapper wallets all contriving to make for very challenging conditions recently. In fact there had only been around 5000 cryptokitties born in the whole first half of 2021.<br /><br />However with eth price halving and gas prices right down the final push to 2 million was on and the last 750 kitties all came in one day. A small point of order however as kitty #2000100 is technically the 2 millionth cryptokitty due to the 100 missing cryptokitties (stay tuned for an article on this topic soon).'}
        />
        <Story
          date={'12th May 2021'}
          title={'Cryptopunks sell for $16.9 million at Christies'}
          img={cryptopunks}
          extUrl={'https://www.christies.com/lot/lot-larva-labs-9-cryptopunks-2-532-58-6316969/?lid=1&from=relatedlot&intobjectid=6316969'}
          copy={'NFTs went "under the hammer" in New York yesterday at world renowned auction house Christies as 9 <a href="https://www.larvalabs.com/cryptopunks" target="_blank">Cryptopunks</a> sold for $16.8 million (USD). The set of 9 included #2, #9 and a super rare "alien". From the Christie\'s website: &quot;To own NFT-based art is to own the beating heart and intangible soul of an artwork, with an indestructible Certificate of Authenticity included.&quot; who clearly sound sold on both the concept and importance of non-fungible tokens in the art world.'}
        />
        {/* <Story
          date={'1st March 2021'}
          title={'Kitty.Family celebrates 3rd anniversary'}
          img={kittyFam}
          extUrl={'https://www.kitty.family'}
          copy={'Kitty.family is a family tree style block explorer that helps you see your cryptokitties relatives - and maybe help you make breeding decisions and search for hidden family gems / interesting relatives.<br /><br />Currently showing up to 4 generations (with plans to increase this) kitty.family celebrated its 3rd anniversary recently and relaunched with an updated UI and a roadmap to dappify the site to allow for users to sign in with metamask and buy any kitties currently on sale.'}
        /> */}
      </div>
      <div style={{ paddingTop: '36px' }}>
        <Yesterday volume={[...volume].reverse()} />
        <h3>New to market</h3>
        <Auctions {...{ account, handlePurchase }} />
        {latestFounderSale && <Latest kitty={latestFounderSale[0]} title={'Latest Founder Sale'} />}
        {latestGen100 && <Latest kitty={latestGen100} title={'Latest Gen100'} />}
        <h3>{'Articles...'}</h3>
        <p><b>Oct 10th 2022</b></p>
        <p style={{ textAlign: 'left', marginBottom: '12px', width: '90%', marginLeft: '5%' }}><b><Link to={'/article/rarity-within-cryptoKitties-low-gen-mutations'}>Rarity within CK: low-gen mutations</Link></b><br />MX at GenX CryptoKitties are rare - and especially so in combination</p>
        <p style={{ textAlign: 'right', marginBottom: '40px', width: '90%', marginLeft: '5%' }}><b><Link to={'/article/rarity-within-cryptoKitties-low-gen-mutations'}>Read more</Link></b></p>
      </div>
    </Styled.Section>
    
    {/* <Styled.Div>
      <Styled.Column>
        
        <Styled.SubColumn>
          
        </Styled.SubColumn>
        <Styled.SubColumn>
          {totals && <Yesterday {...{ overview }} />}
          <AtAGlance pregnant={pregnant.total} resting={resting.total} />
          <h3 className={'columnHeading'}>{'Articles...'}</h3>
          <p><b>Oct 10th 2022</b></p>
          <p style={{ textAlign: 'left', marginBottom: '40px' }}><b><Link to={'/article/rarity-within-cryptoKitties-low-gen-mutations'}>Rarity within cryptoKitties: low-gen mutations</Link></b> MX at GenX CryptoKitties are rare - and especially so in combination</p>
          {diamond.length > 0 && <Latest kitty={diamond[0]} title={'Diamond Floor'} {...{ handlePurchase, handleSire }} />}
          {exclusive.length > 0 && <Latest kitty={exclusive[0]} title={'Exclusive Floor'} {...{ handlePurchase, handleSire }} />}
          {founders.length > 0 && <Latest kitty={founders[0]} title={'Founders Floor'} {...{ handlePurchase, handleSire }} />}
          {sires.length > 0 && <Latest kitty={sires[0]} title={'Need a friend?'} {...{ handlePurchase, handleSire }} sire={true} />}
          {gen0.length > 0 &&  <Latest kitty={gen0[0]} title={'Gen0 Floor'} {...{ handlePurchase, handleSire }} />}
          {gen100.length > 0 &&  <Latest kitty={gen100[0]} title={'Latest Gen100'} {...{ handlePurchase, handleSire }} />}
          <div style={{ padding: '0 5%', marginBottom: '40px'}}>
            <a href={'https://kitty.family'} target={'_blank'}>
              <img src={kittyFamily} style={{ width: '100%', borderRadius: '5px', border: '2px inset #444', marginBottom: '10px' }} />
            </a>
            <p>Visit <a href={'https://kitty.family'} target={'_blank'}>Kitty.Family</a> to find out your CryptoKitty's family tree.</p>
          </div>
        </Styled.SubColumn>
      </Styled.Column>
      <Styled.Column>
        
        <Cattributes
          totalKitties={latestKitties[0].kittyid}
          {...{ cattributes, distinctCattributes }}
        />
      </Styled.Column>
      </Styled.Div> */}
    </>
  )
}

const mapStateToProps = ({ socket }) => ({ socket })
const connected = connect(mapStateToProps, { fetchSocket })(Homepage)
export default connected
