import styled from 'styled-components'

export const Div = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 95%;
    margin: 20px 2.5%;
    > div {
        text-align: left;
        margin-bottom: 10px;
        font-weight: bold;
        width: 25%;
        font-size: 14px;
        @media (min-width: 450px) {
            width: 20%;
            font-size: 16px;
        }
        @media (min-width: 700px) {
            width: 14.282%;
        }
        @media (min-width: 1200px) {
            width: 6.66666666667%;
        }
        > a, span {
            color: rgba(255, 116, 228, 1);
        }
    }
`