import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import socket from 'App/reducer'
import markets from 'pages/Markets/reducer'
import overview from 'components/Yesterday/reducer'
import modal from 'components/Modal/reducer'

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    socket,
    markets,
    overview,
    modal
  })

export default reducer
