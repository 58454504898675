import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utils';
import * as Styled from './GeneTree.style';

const GeneTree = ({ geneType, genes, floors, floorTotals, totals }) => {

  const getGeneName = (pos, truncated) => {
    const _name = genes[pos][geneType]
    const total = totals[`g${pos}`]
    const floor = floors[`g${pos}`]
    const floorTotal = floorTotals[`g${pos}`]
    return (
      <Link to={`/cattribute/${_name}`}>{_name}
        <span>{formatPrice(floor)}</span>
        {!truncated && <span>{floorTotal.toLocaleString()} - <span>{((100 / total) * floorTotal).toFixed(2)}%</span></span>}
      </Link>
    )
  }

  return (
    <Styled.Div>
      <div>
        {[30].map(c => 
          <Styled.Span
            key={c}
            highlight={window.location.href.indexOf(genes[c][geneType]) > -1}
          >{getGeneName(c)}
          </Styled.Span>
        )}
      </div>
      <div>
        {[28,29].map(c => 
          <Styled.Span
            key={c}
            highlight={window.location.href.indexOf(genes[c][geneType]) > -1}
          >{getGeneName(c)}
          </Styled.Span>
        )}
      </div>
      <div>
        {[24,25,26,27].map(c => 
          <Styled.Span
            key={c}
            highlight={window.location.href.indexOf(genes[c][geneType]) > -1}
          >{getGeneName(c)}
          </Styled.Span>
        )}
      </div>
      <div>
        {[16,17,18,19,20,21,22,23].map(c => 
          <Styled.Span
            key={c}
            highlight={window.location.href.indexOf(genes[c][geneType]) > -1}
          >{getGeneName(c)}
          </Styled.Span>
        )}
      </div>
      {!(geneType === 'we' || geneType === 'en') &&
        <div>
          {[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15].map(c => 
            <Styled.Span
              key={c}
              highlight={window.location.href.indexOf(genes[c][geneType]) > -1}
            >{getGeneName(c, true)}
            </Styled.Span>
        )}
        </div>
      }
    </Styled.Div>
  );
};

export default GeneTree;
