import React, { Component } from 'react'

export default ChartComponent => (
  class ResponsiveChart extends Component {
    constructor(props) {
      super(props)
      this.state = {
        containerWidth: null,
        showGraph: false
      }

      this.fitParentContainer = this.fitParentContainer.bind(this)
    }

    componentDidMount() {
      this.fitParentContainer()
      window.addEventListener('resize', this.fitParentContainer)
      document.addEventListener('click', this.fitParentContainer)
      document.addEventListener('fadeInResize', this.delayFit)

    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
      document.removeEventListener('click', this.fitParentContainer)
      document.removeEventListener('fadeInResize', this.delayFit)
    }

    delayFit() {
      setTimeout(() => this.fitParentContainer(),500);
    }

    fitParentContainer() {
      const { containerWidth } = this.state
      const currentContainerWidth = this.chartContainer
        .getBoundingClientRect().width
      const shouldResize = containerWidth !== currentContainerWidth

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
        })
      }
    }

    renderChart() {
      const { containerWidth } = this.state

      return (
        <ChartComponent {...this.props} parentWidth={containerWidth} />
      )
    }

    render() {
      const { containerWidth } = this.state
      const shouldRenderChart = containerWidth !== null

      return (
        <div
          ref={(el) => { this.chartContainer = el }}
          className="Responsive-wrapper"
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      )
    }
  }
)
