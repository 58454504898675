import {
  REQUEST_KITTY, RECEIVE_KITTY,
  ERROR_KITTY
} from './actions'

const initialState = {
  kitty: {},
  kittyReady: false,
  error: false
}

const modalReducer = function statsReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_KITTY:
      return {
          ...state,
          kitty: {},
          kittyReady: false
        }
    case RECEIVE_KITTY:
      return {
          ...state,
          kitty: action.payload,
          kittyReady: true
        }
    case ERROR_KITTY:
      return {
        ...state,
        error: action.payload
      }

    default:
      return state
  }
}

export default modalReducer
