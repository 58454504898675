import styled from 'styled-components'

export const Div = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  bottom: 20px;
  left: 20px;
  width: auto;
  font-weight: bold;
  background-color: #FFF;
  padding: 4px 10px;
  border-radius: 5px;
  text-align: center;
  img {
    width: 16px;
    margin-right: 4px;
  }
  > p {
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    &.underlineOnHover {
      &:hover {
        text-decoration: underline;
      }
    }
    > span {
      display: inline-block;
      max-width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 2px;
    }
  }
`
