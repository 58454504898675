import React, { Component } from 'react'
import { scaleLinear, scaleBand } from 'd3-scale'
import ResponsiveWrapper from './ResponsiveWrapper'
import { formatDate, formatPrice, getTimeLength } from 'utils'
import Axes from './Axes'
import Bars from './Bars'
import Lines from './Lines'
import * as Styled from './Chart.style'
const BigNumber = require('big-number')

class BarChart extends Component {
  constructor(props) {
    super(props)
    this.xScale = scaleLinear()
    this.yScale = scaleLinear()
    this.yScale2 = scaleLinear()
    this.yScale3 = scaleLinear()
    this.yScale4 = scaleLinear()
  }

  render() {
    const { parentWidth, dataFeed, color1, color2, color3, color4 } = this.props
    const margins = { top: 10, right: 10, bottom: 10, left: 10 }
    const svgDimensions = {
      width: Math.max(parentWidth, 300),
      height: 300
    };

    let maxXValues = [],
        maxYValues = [],
        maxYValues2 = [],
        maxYValues3 = [],
        maxYValues4 = [],
        dataForChart = [],
        dataForChart2 = [],
        dataForChart3 = [],
        dataForChart4 = []

    let totalBirths = 0
    let saleVolumeTotal = 0

    dataFeed.map((d, i = 1) => {
      let q = {}
      let p = {}
      let b = {}
      let e = {}
      maxXValues.push(i);
      q.blockn = i
      p.blockn = i
      b.blockn = i
      e.blockn = i
      q.price = d.SaleVolumeUSD
      p.price = formatPrice(d.SaleVolume,0);
      b.price = d.BirthTotal
      e.price = d.ethprice;
      maxYValues.push(q.price);
      maxYValues2.push(p.price);
      maxYValues3.push(b.price);
      maxYValues4.push(e.price);
      dataForChart.push(q);
      dataForChart2.push(p);
      dataForChart3.push(b);
      dataForChart4.push(e);
      return i++
    });

    const maxXValue = Math.max(...maxXValues)
    const minXValue = Math.min(...maxXValues)
    const maxYValue = Math.max(...maxYValues)
    const maxYValue2 = Math.max(...maxYValues2)
    const maxYValue3 = Math.max(...maxYValues3)
    const maxYValue4 = Math.max(...maxYValues4)

    let xScale = this.xScale
        .domain([minXValue, maxXValue])
        .range([margins.left, svgDimensions.width - margins.right])
    const yScale = this.yScale
        .domain([0, maxYValue])
        .range([svgDimensions.height - margins.bottom, margins.top])

    const yScale2 = this.yScale2
        .domain([0, maxYValue2])
        .range([svgDimensions.height - margins.bottom, margins.top])

    const yScale3 = this.yScale3
        .domain([0, maxYValue3])
        .range([svgDimensions.height - margins.bottom, margins.top])

    const yScale4 = this.yScale4
        .domain([0, maxYValue4])
        .range([svgDimensions.height - margins.bottom, margins.top])

    let nLines = 5

    return (
      <>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <Axes
              scales={{ xScale, yScale }}
              margins={margins}
              svgDimensions={svgDimensions}
          />
          {[...Array(nLines)].map((v, i)=>
            <rect key={i} width={svgDimensions.width} height={svgDimensions.height / nLines} style={{ fill: (i%2 ? 'rgb(255,255,255)' : 'rgb(247,247,247)') }} y={(svgDimensions.height / nLines) * i} />
          )}

          <Lines
            scales={{ xScale, yScale }}
            { ...{ margins, svgDimensions }}
            width={'20px'}
            data={dataForChart}
            dataForLabels={dataFeed}
            color={color1}
            selectX={dataForChart => dataForChart.blockn}
            selectY={dataForChart => dataForChart.price}
            dotRadius={0}
          />

          <Lines
            scales={{ xScale: xScale, yScale: yScale2 }}
            { ...{ margins, svgDimensions }}
            width={'20px'}
            data={dataForChart2}
            dataForLabels={dataFeed}
            color={color2}
            selectX={dataForChart => dataForChart.blockn}
            selectY={dataForChart => dataForChart.price}
            dotRadius={0}
          />
          <Lines
            scales={{ xScale: xScale, yScale: yScale3 }}
            { ...{ margins, svgDimensions }}
            width={'20px'}
            data={dataForChart3}
            dataForLabels={dataFeed}
            color={color3}
            selectX={dataForChart => dataForChart.blockn}
            selectY={dataForChart => dataForChart.price}
            dotRadius={0}
          />
          <Lines
            scales={{ xScale: xScale, yScale: yScale4 }}
            { ...{ margins, svgDimensions }}
            width={'20px'}
            data={dataForChart4}
            dataForLabels={dataFeed}
            color={color4}
            selectX={dataForChart => dataForChart.blockn}
            selectY={dataForChart => dataForChart.price}
            dotRadius={0}
          /> 
        </svg>
        {dataFeed.length &&
          <Styled.ChartInfo>
            <div>
              <span>{formatDate(dataFeed[0].timestamp)}</span>
            </div>
            <div>
              <span>- {getTimeLength(dataFeed[dataFeed.length - 1].timestamp, dataFeed[0].timestamp).split(' ')[0]} {getTimeLength(dataFeed[dataFeed.length - 1].timestamp, dataFeed[0].timestamp).split(' ')[1]} -</span>
              <span>Total (ETH): <b>Ξ{parseFloat(formatPrice(dataFeed[dataFeed.length - 1].SaleVolumeTotal, 3)).toLocaleString()}</b></span>
              <span>Total (USD): <b>${parseFloat(parseFloat(dataFeed[dataFeed.length - 1].SaleVolumeUSDTotal).toFixed(2)).toLocaleString()}</b></span>
            </div>
            <div>
              <span>{formatDate(dataFeed[dataFeed.length - 1].timestamp)}</span>
            </div>
          </Styled.ChartInfo>
        }
      </>
    )
  }
}

export default ResponsiveWrapper(BarChart)
