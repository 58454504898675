import styled from "styled-components";

export const Li = styled.li`
    border: 1px solid #CCC;
    padding: 4px 8px;
    margin-right: 12px;
    cursor: pointer;
    background-color: ${({ selected }) => selected ? '#EEE' : 'transparent'};
    &:last-of-type {
        margin-right: 0;
    }
    &:hover {
        text-decoration: ${({ selected }) => selected ? 'none' : 'underline'};
    }
`

export const Div = styled.div`
    > ul {
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }
    > div {
        display: flex;
        margin: 0 5% 64px;
        > div {
            &:first-of-type {
               flex: 1;
               text-align: left;
               overflow: hidden;
               > h4 {
                    font-size: 18px;
                    margin-bottom: 8px;
                    > b {
                        font-weight: bold;
                        margin: 0 12px 0 0;
                    }
               }
               > p {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 26px;
                span {
                    
                }
                > b {
                    font-weight: bold;
                    margin: 0 12px 0 6px;
                }
                > svg {
                    width: 16px;
                    height: 16px;
                    margin: 0 6px;
                    &:hover {
                        cursor: pointer;
                    }
                }
               }
            }
            &:last-of-type {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 10px;
                > div {
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                    overflow: hidden;
                    border: 2px solid #333;
                    background-size: 180%;
                    background-position: 46% 32%;
                    margin-bottom: 12px;
                }
                > p {
                    > b {
                        display: block;
                        font-weight: bold;
                        margin-bottom: 8px;
                    }
                }
                > button {
                    border-radius: 5px;
                    font-family: "bungee",sans-serif;
                    font-weight: normal;
                    background-color: #EEE;
                    border: 1px solid #CCC;
                    padding: 0 20px;
                    font-size: 10px;
                }
            }
            
        }
    }
`