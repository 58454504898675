import styled from 'styled-components'

export const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  > img {
    width: 80px;
    margin: 100px auto;
  }
`

export const ChartInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 10px;
  > div {
    &:first-of-type {
      text-align: left;
    }
    &:last-of-type {
      text-align: right;
    }
    span {
      display: block;
    }
  }
`
