import {
  FLOOR_BY_TYPE_REQUEST,
  FLOOR_BY_TYPE_RECEIVED,
  FLOOR_BY_TYPE_ERROR
} from './actions'

const initialState = {
  kitties: [],
  loading: false
}

const marketsReducer = function kittiesReducer (state = initialState, action) {
  switch (action.type) {
    case FLOOR_BY_TYPE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FLOOR_BY_TYPE_RECEIVED:
      return {
        ...action.payload,
        loading: false
      }
    case FLOOR_BY_TYPE_ERROR:
      return {
        kitties: [],
        loading: false
      }
    default:
      return state
  }
}

export default marketsReducer
