import { Link as _Link } from 'react-router-dom'
import styled from 'styled-components'

export const Div = styled.div`
  > div {
    border: 1px dotted #444;
    margin: 0 5% 40px;
    padding: 40px 0;
    h3 {
      font-size: 20px;
      margin-bottom: 32px;
      text-transform: uppercase;
      color: #555;
      &:last-of-type {
        margin-bottom: 0px;
      }
      > span {
        display: block;
        font-size: 16px;
        margin-top: 10px;
        opacity: 0.8;

      }
      b {
        color: rgb(239, 82, 209);
        margin-left: 4px;
        display: inline-block;
        > span {
          display: inline-block;
          margin-right: 2px;
        }
      }
      > a {
        color: rgb(239, 82, 209);
      }
    }
  }
`

export const Link = styled(_Link)`
  display: block;
  margin-top: 20px;
`
