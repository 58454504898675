import {
  REQUEST_OVEVIEW,
  RECEIVE_OVERVIEW
} from './actions'

const initialState = {
  yesterday: {
    births: undefined,
    totalBirths: undefined,
    sales: undefined,
    totalSales: undefined,
    volume: undefined,
    total: undefined,
  },
  totals: false,
  loading: false,
}

const overviewReducer = function statsReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_OVEVIEW:
      return {
        ...state,
        loading: true
      }
    case RECEIVE_OVERVIEW:
      return {
        loading: false,
        ...action.payload
      }
    default:
      return state
  }
}

export default overviewReducer
