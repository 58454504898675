import styled from 'styled-components'
import * as Styled from 'style'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  h1 {
    font-size: 40px;
    margin: 0 0 22px;
    > span {
      display: inline-block;
      font-size: 24px;
      margin-left: 20px;
    }
  }
  h3 {
    width: 100%;
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
    color: #404040;
  }
`

export const FloorDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 5% 50px;
  > div {
    width: 50%;
    @media (min-width: 780px) {
      width: 25%;
    }
    @media (min-width: 1180px) {
      width: 16.6%;
    }
    margin-bottom: 30px;
  }
`

export const Section = styled.div`
  width: 90%;
  margin: 0 5% 40px;
`
