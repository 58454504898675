import styled from 'styled-components'

export const H3 = styled.h3`
  position: fixed;
  background-color: #FFF;
  box-sizing: border-box;
  border-radius: 10px;
  top: 20px;
  right: 20px;
  text-align: right;
  font-size: 12px;
  padding: 4px 10px;
  @media (min-width: 800px) {
    padding: 6px 20px;
  }
  cursor: default;
  vertical-align: baseline;
  z-index: 100;
  img {
    width: 16px;
    margin: 0 16px -4px 16px;
  }
  span {
    cursor: pointer;
    color: #333;
  }
`
