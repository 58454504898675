import { useEffect, useState  } from "react";
import axios from 'axios'
import moment from 'moment'
import { DATE_FORMAT, readableLength  } from "utils";
import * as Styled from './Auctions.style'
import { BN, fromWei } from "web3-utils";
import { ReactComponent as Clock } from 'svg/clock.svg'
import { ReactComponent as Search } from 'svg/search.svg'
import { genes } from 'utils'
import loader from 'img/loader.gif'
import { API_URL, getCurrentPrice, getTimestamp } from 'utils'

const kittyClock = '0xba52c75764d6f594735dc735be7f1830cdf58ddf'

const Auctions = ({ account, handlePurchase }) => {
    const [auctions, setAuctions] = useState(undefined)
    const [mtype, setMtype] = useState(0)
    useEffect(() => {
        setAuctions(undefined)
        if (mtype === 0) {
            axios.get(API_URL + '/sales/new').then(({ data }) => {
                setAuctions(data.slice(0, 5))
            })
        } else {
            axios.get(`${API_URL}/sales/new?m=${mtype}`).then(({ data }) => {
                setAuctions(data.slice(0, 5))
            }) 
        }
    }, [mtype])

    return (
        <Styled.Div>
            <ul>
                {[0,1,2,3,4].map(m => 
                    <Styled.Li 
                      key={m}
                      selected={m === mtype}
                      onClick={() => setMtype(m)}
                    >
                        {m === 0 && 'All'}
                        {m === 1 && '>=1 M1'}
                        {m === 2 && '>=1 M2'}
                        {m === 3 && '>=1 M3'}
                        {m === 4 && '>=1 M4'}
                    </Styled.Li>
                )}
            </ul>
            {auctions ? (
                auctions.map((kitty, key) => {
                    const now = getTimestamp()
                    const currentPrice = getCurrentPrice(kitty.startingPrice, kitty.endingPrice, kitty.timestamp, kitty.endTime.toString(), now)
                    return (
                        <div {...{ key }}>
                            <div>
                                <h4><b>#{kitty.tokenId} </b>{' Gen:'}{kitty.gen}</h4>
                                <p>Born: {moment.utc(kitty.hatched.timestamp  * 1000).format(DATE_FORMAT)}</p>
                                <p><span>Seller: </span> {kitty.owner}</p>
                                <p><span>Hatched By: </span> {kitty.owners[kitty.owners.length - 1] === kittyClock ? (
                                    <>
                                        {' '}<Clock />{' Kitty Clock'}
                                    </>
                                ) : (
                                    kitty.owners[kitty.owners.length - 1]
                                )}</p>
                                <p><b>Ξ{fromWei(kitty.startingPrice)} {'->'} Ξ{fromWei(kitty.endingPrice)}</b></p>
                                {/* <p>start time: {moment.utc(kitty.timestamp  * 1000).format(DATE_FORMAT)}</p>
                                <p>end time: {moment.utc(kitty.endTime  * 1000).format(DATE_FORMAT)}</p> */}
                                <p>duration: {readableLength(kitty.duration)}</p>
                                {kitty.gen > 0 && (
                                    <p>m: {kitty.gen > 0 ? <b>{` #${kitty.matronId}`}</b> : '-'} s: {kitty.gen > 0 ? <b>{`#${kitty.sireId}`}</b> : '-'}</p>
                                )}
                                <Offspring {...kitty} />
                            </div>
                            <div>
                                <div 
                                    className={`${genes[kitty.g8].ec}`}
                                    style={{ backgroundImage: 
                                        `
                                         url('https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.svg'),
                                         url('https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.png')
                                        `,
                                    }} 
                                />
                                <p><b>Ξ{fromWei(currentPrice)}</b></p>
                                <button disabled={!account} onClick={() => handlePurchase(kitty.tokenId, currentPrice)}>Buy Now</button>
                                {!account && <p style={{ fontSize: '10px', lineHeight: '14px', marginTop: '6px' }}>Sign in with<br />metamask to buy</p>}
                            </div>
                        </div>
                    )
                })
            ) : (
                <img src={loader} alt={''} />
            )}
        </Styled.Div>
    )
}

export default Auctions

const Offspring = kitty => {
    const [showOffspring, setShowOffspring] = useState(false)
    return (
        <>
            <p>offspring: {kitty.offspring} {kitty.offspring > 0 && <Search onClick={() => setShowOffspring(!showOffspring)} />}</p>
            {kitty.offspring > 0 && showOffspring && (
                <p>
                    {kitty.offspringIds.map(id => 
                        <b key={id}>-#{id}</b>
                    )}
                </p>    
            )}
        </>
    )
}