import axios from 'axios'
import { API_URL } from 'utils'

let baseRequest = axios.create({ baseURL: API_URL + '/' })

export const REQUEST_OVEVIEW = 'REQUEST_OVEVIEW'
export const RECEIVE_OVERVIEW = 'RECEIVE_OVERVIEW'

export const requestOverview = (start, end) => dispatch => {
  dispatch({ type: REQUEST_OVEVIEW })
  baseRequest.get(`totals`).then(({ data: totals }) => {
    baseRequest.get(`yesterday`).then(({ data: yesterday }) => {
      const payload = { yesterday, totals }
      dispatch({ type: RECEIVE_OVERVIEW, payload })
    })
  })
}
