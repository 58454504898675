import moment from 'moment'
import prettyMs from 'pretty-ms'
import { BN, fromWei } from 'web3-utils'

export const API_URL = "https://api.cryptosystems.online/cryptokitties"

export const DATE_FORMAT = 'Do MMMM YYYY'
export const DATETIME_FORMAT = 'hh:mm:ssa - Do MMMM YYYY'

export const readableLength = durationInSeconds => {
  const duration = moment.duration(durationInSeconds, 'seconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const parts = [];

  if (days > 0) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`);
  }

  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }

  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }

  if (seconds > 0) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  }
  return parts.join(', ');
}

export const formatPrice = price => {
  if (price) {
    const formattedPrice = Number(fromWei(price.replace(/^0+/, '')));
    const roundedPrice = formattedPrice.toFixed(5);
    return `Ξ${parseFloat(roundedPrice)}`;
  }
}

export const getBgColor = (col, colors) => {
  let color = colors.find(c => c.name === col)
  return color.backgroundColorHex
}

export const handleGetCoolDown = (gen, offspring) => {
  const cdIncrement = gen === 0 ? gen : 1
  const cd = gen + offspring
  return cd <= cdIncrement ? "Fast" :
    cd <= cdIncrement + 4 ? "Swift" :
      cd <= cdIncrement + 8 ? "Snappy" :
        cd <= cdIncrement + 12 ? "Brisk" :
          cd <= cdIncrement + 16 ? "Plodding" :
            cd <= cdIncrement + 20 ? "Slow" :
              cd <= cdIncrement + 24 ? "Sluggish" : "Catatonic"
}

export const formatDate = (date) => moment.utc(date * 1000).format('Do MMM YYYY')
export const formatDateTime = (date) => moment.utc(date * 1000).format('Do MMM YYYY h:mma')
export const getTimeLength = (b1,b2) => prettyMs(1000 * (-1 * (b2 - b1)))

export const handleGetBirthday = (date) => {
  const birthday = moment.utc(date).format('Do MMMM YYYY')
  return birthday
}

export const convertUnixDate = (date) => {
  const converted = moment.unix(date).format('Do MMMM YYYY')
  return converted
}

export const getTimestamp = () => {
  const utcDate = new Date();
  const timestamp = Math.floor(utcDate.getTime() / 1000);
  return timestamp;
}

export const getCurrentPrice = (startP, endP, startT, endT, now) => {
  if (startP === endP) {
      return startP
  }
  if (endT > now) {
    try {
      const divisor = new BN(100)
      const duration = new BN(endT).sub(new BN(startT))
      const multiplier = new BN(now).sub(new BN(startT))
      const saleDurationPercentage = divisor.mul(multiplier).div(duration)
      const saleDiff = new BN(endP).sub(new BN(startP))
      const salePriceAdjustment = saleDurationPercentage.mul(saleDiff).div(divisor)
      const saleCurrentPrice = new BN(startP).add(salePriceAdjustment)
      return saleCurrentPrice.toString()
    } catch (e) { 
      console.log(e)
    }
  } else {
      return endP
  }
}

export const catTypes = [
  { param: 'fu', readable: 'Body / Fur' },
  { param: 'pa', readable: 'Pattern' },
  { param: 'ec', readable: 'Eye Color' },
  { param: 'es', readable: 'Eye Shape' },
  { param: 'bc', readable: 'Base Color' },
  { param: 'hc', readable: 'Highlight Color' },
  { param: 'ac', readable: 'Accent Color' },
  { param: 'we', readable: 'Wild' },
  { param: 'mo', readable: 'Mouth' },
  { param: 'en', readable: 'Environment' },
  { param: 'se', readable: 'Secret' },
  { param: 'pu', readable: 'Purrstige' }
]

export const genes = [
  {
    binary: '00000',
    kai: '1',
    fu: 'savannah',
    pa: 'vigilante',
    ec: 'thundergrey',
    es: 'swarley',
    bc: 'strawberry', // show be shadowgrey
    hc: 'cyborg',
    ac: 'belleblue',
    we: '',
    mo: 'whixtensions',
    en: '',
    se: 'se00',
    pu: 'pu00'
  },
  {
    binary: '00001',
    kai: '2',
    fu: 'selkirk',
    pa: 'tiger',
    ec: 'gold',
    es: 'wonky',
    bc: 'salmon',
    hc: 'springcrocus',
    ac: 'sandalwood',
    we: '',
    mo: 'wasntme',
    en: '',
    se: 'se01',
    pu: 'pu01'
  },
  {
    binary: '00010',
    kai: '3',
    fu: 'chantilly',
    pa: 'rascal',
    ec: 'topaz',
    es: 'serpent',
    bc: 'meowgarine',
    hc: 'egyptiankohl',
    ac: 'peach',
    we: '',
    mo: 'wuvme',
    en: '',
    se: 'se02',
    pu: 'pu02'
  },
  {
    binary: '00011',
    kai: '4',
    fu: 'birman',
    pa: 'ganado',
    ec: 'mintgreen',
    es: 'googly',
    bc: 'orangesoda',
    hc: 'poisonberry',
    ac: 'icy',
    we: '',
    mo: 'gerbil',
    en: '',
    se: 'se03',
    pu: 'pu03'
  },
  {
    binary: '00100',
    kai: '5',
    fu: 'koladiviya',
    pa: 'leopard',
    ec: 'isotope',
    es: 'otaku',
    bc: 'cottoncandy',
    hc: 'lilac',
    ac: 'granitegrey',
    we: '',
    mo: 'confuzzled',
    en: '',
    se: 'se04',
    pu: 'pu04'
  },
  {
    binary: '00101',
    kai: '6',
    fu: 'bobtail',
    pa: 'camo',
    ec: 'sizzurp',
    es: 'simple',
    bc: 'mauveover',
    hc: 'apricot',
    ac: 'cashewmilk',
    we: '',
    mo: 'impish',
    en: '',
    se: 'se05',
    pu: 'pu05'
  },
  {
    binary: '00110',
    kai: '7',
    fu: 'manul',
    pa: 'rorschach',
    ec: 'chestnut',
    es: 'crazy',
    bc: 'aquamarine',
    hc: 'royalpurple',
    ac: 'kittencream',
    we: '',
    mo: 'belch',
    en: '',
    se: 'se06',
    pu: 'pu06'
  },
  {
    binary: '00111',
    kai: '8',
    fu: 'pixiebob',
    pa: 'spangled',
    ec: 'strawberry',
    es: 'thicccbrowz',
    bc: 'nachocheez',
    hc: 'padparadscha',
    ac: 'emeraldgreen',
    we: '',
    mo: 'rollercoaster',
    en: '',
    se: 'se07',
    pu: 'pu07'
  },
  {
    binary: '01000',
    kai: '9',
    fu: 'siberian',
    pa: 'calicool',
    ec: 'sapphire',
    es: 'caffeine',
    bc: 'harbourfog',
    hc: 'swampgreen',
    ac: 'kalahari',
    we: '',
    mo: 'beard',
    en: '',
    se: 'se08',
    pu: 'pu08'
  },
  {
    binary: '01001',
    kai: 'a',
    fu: 'cymric',
    pa: 'luckystripe',
    ec: 'forgetmenot',
    es: 'wowza',
    bc: 'cinderella',
    hc: 'violet',
    ac: 'shale',
    we: '',
    mo: 'pouty',
    en: '',
    se: 'se09',
    pu: 'pu09'
  },
  {
    binary: '01010',
    kai: 'b',
    fu: 'chartreux',
    pa: 'amur',
    ec: 'dahlia',
    es: 'baddate',
    bc: 'greymatter',
    hc: 'lemonade',
    ac: 'purplehaze',
    we: '',
    mo: 'saycheese',
    en: '',
    se: 'se10',
    pu: 'pu10'
  },
  {
    binary: '01011',
    kai: 'c',
    fu: 'himalayan',
    pa: 'jaguar',
    ec: 'coralsunrise',
    es: 'asif',
    bc: 'tundra',
    hc: 'barkbrown',
    ac: 'hanauma',
    we: '',
    mo: 'grim',
    en: '',
    se: 'se11',
    pu: 'pu11'
  },
  {
    binary: '01100',
    kai: 'd',
    fu: 'munchkin',
    pa: 'spock',
    ec: 'olive',
    es: 'chronic',
    bc: 'brownies',
    hc: 'coffee',
    ac: 'azaleablush',
    we: '',
    mo: 'fangtastic',
    en: '',
    se: 'se12',
    pu: 'pu12'
  },
  {
    binary: '01101',
    kai: 'e',
    fu: 'sphynx',
    pa: 'mittens',
    ec: 'doridnudibranch',
    es: 'slyboots',
    bc: 'dragonfruit',
    hc: 'coffee',
    ac: 'missmuffett',
    we: '',
    mo: 'moue',
    en: '',
    se: 'se13',
    pu: 'pu13'
  },
  {
    binary: '01110',
    kai: 'f',
    fu: 'ragamuffin',
    pa: 'totesbasic',
    ec: 'parakeet',
    es: 'wiley',
    bc: 'hintomint',
    hc: 'chocolate',
    ac: 'morningglory',
    we: '',
    mo: 'happygokitty',
    en: '',
    se: 'se14',
    pu: 'pu14'
  },
  {
    binary: '01111',
    kai: 'g',
    fu: 'ragdoll',
    pa: 'totesbasic',
    ec: 'cyan',
    es: 'stunned',
    bc: 'bananacream',
    hc: 'butterscotch',
    ac: 'frosting',
    we: '',
    mo: 'soserious',
    en: '',
    se: 'se15',
    pu: 'pu15'
  },
  {
    binary: '10000',
    kai: 'h',
    fu: 'norwegianforest',
    pa: 'splat',
    ec: 'pumpkin',
    es: 'chameleon',
    bc: 'cloudwhite',
    hc: 'ooze',
    ac: 'daffodil',
    we: 'littlefoot',
    mo: 'cheeky',
    en: 'salty',
    se: 'se16',
    pu: 'pu16'
  },
  {
    binary: '10001',
    kai: 'i',
    fu: 'mekong',
    pa: 'thunderstruck',
    ec: 'limegreen',
    es: 'alien',
    bc: 'cornflower',
    hc: 'safetyvest',
    ac: 'flamingo',
    we: 'elk',
    mo: 'starstruck',
    en: 'dune',
    se: 'se17',
    pu: 'pu17'
  },
  {
    binary: '10010',
    kai: 'j',
    fu: 'highlander',
    pa: 'dippedcone',
    ec: 'bridesmaid',
    es: 'fabulous',
    bc: 'oldlace',
    hc: 'turtleback',
    ac: 'buttercup',
    we: 'ducky',
    mo: 'samwise',
    en: 'juju',
    se: 'se18',
    pu: 'pu18'
  },
  {
    binary: '10011',
    kai: 'k',
    fu: 'balinese',
    pa: 'highsociety',
    ec: 'bubblegum',
    es: 'raisedbrow',
    bc: 'koala',
    hc: 'rosequartz',
    ac: 'bloodred',
    we: 'trioculus',
    mo: 'ruhroh',
    en: 'tinybox',
    se: 'se19',
    pu: 'pu19'
  },
  {
    binary: '10100',
    kai: 'm',
    fu: 'lynx',
    pa: 'tigerpunk',
    ec: 'twilightsparkle',
    es: 'tendertears',
    bc: 'lavender',
    hc: 'wolfgrey',
    ac: 'atlantis',
    we: 'daemonwings',
    mo: 'dali',
    en: 'myparade',
    se: 'se20',
    pu: 'pu20'
  },
  {
    binary: '10101',
    kai: 'n',
    fu: 'mainecoon',
    pa: 'henna',
    ec: 'palejade',
    es: 'hacker',
    bc: 'glacier',
    hc: 'cerulian',
    ac: 'summerbonnet',
    we: 'featherbrain',
    mo: 'grimace',
    en: 'finalfrontier',
    se: 'se21',
    pu: 'pu21'
  },
  {
    binary: '10110',
    kai: 'o',
    fu: 'laperm',
    pa: 'arcreactor',
    ec: 'pinefresh',
    es: 'sass',
    bc: 'redvelvet',
    hc: 'skyblue',
    ac: 'periwinkle',
    we: 'flapflap',
    mo: 'majestic',
    en: 'metime',
    se: 'se22',
    pu: 'pu22'
  },
  {
    binary: '10111',
    kai: 'p',
    fu: 'persian',
    pa: 'totesbasic',
    ec: 'eclipse',
    es: 'sweetmeloncakes',
    bc: 'vergigris',
    hc: 'garnet',
    ac: 'patrickstarfish',
    we: 'daemonhorns',
    mo: 'tongue',
    en: 'drift',
    se: 'se23',
    pu: 'pu23'
  },
  {
    binary: '11000',
    kai: 'q',
    fu: 'fox',
    pa: 'scorpius',
    ec: 'babypuke',
    es: 'oceanid',
    bc: 'icicle',
    hc: 'peppermint',
    ac: 'seafoam',
    we: 'dragontail',
    mo: 'yokel',
    en: 'secretgarden',
    se: 'se24',
    pu: 'pu24'
  },
  {
    binary: '11001',
    kai: 'r',
    fu: 'kurilian',
    pa: 'razzledazzle',
    ec: 'downbythebay',
    es: 'wingtips',
    bc: 'onyx',
    hc: 'universe',
    ac: 'cobalt',
    we: 'aflutter',
    mo: 'topoftheworld',
    en: 'frozen',
    se: 'se25',
    pu: 'pu25'
  },
  {
    binary: '11010',
    kai: 's',
    fu: 'toyger',
    pa: 'hotrod',
    ec: 'autumnmoon',
    es: 'firedup',
    bc: 'hyacinth',
    hc: 'royalblue',
    ac: 'mallowflower',
    we: 'foghornpawhorn',
    mo: 'neckbeard',
    en: 'roadtogold',
    se: 'se26',
    pu: 'pu26'
  },
  {
    binary: '11011',
    kai: 't',
    fu: 'manx',
    pa: 'allyouneed',
    ec: 'oasis',
    es: 'buzzed',
    bc: 'martian',
    hc: 'mertail',
    ac: 'mintmacaron',
    we: 'unicorn',
    mo: 'satiated',
    en: 'jacked',
    se: 'se27',
    pu: 'pu27'
  },
  {
    binary: '11100',
    kai: 'u',
    fu: 'lykoi',
    pa: 'avatar',
    ec: 'gemini',
    es: 'bornwithit',
    bc: 'hotcocoa',
    hc: 'inflatablepool',
    ac: 'sully',
    we: 'dragonwings',
    mo: 'walrus',
    en: 'floorislava',
    se: 'se28',
    pu: 'pu28'
  },
  {
    binary: '11101',
    kai: 'v',
    fu: 'burmilla',
    pa: 'gyre',
    ec: 'dioscuri',
    es: 'candyshoppe',
    bc: 'shamrock',
    hc: 'pearl',
    ac: 'fallspice',
    we: 'alicorn',
    mo: 'struck',
    en: 'prism',
    se: 'se29',
    pu: 'pu29'
  },
  {
    binary: '11110',
    kai: 'w',
    fu: 'liger',
    pa: 'moonrise',
    ec: 'kaleidoscope',
    es: 'drama',
    bc: 'firstblush',
    hc: 'prairierose',
    ac: 'dreamboat',
    we: 'wyrm',
    mo: 'delite',
    en: 'junglebook',
    se: 'se30',
    pu: 'pu30'
  },
  {
    binary: '11111',
    kai: 'x',
    fu: '?',
    pa: '?',
    ec: '?',
    es: '?',
    bc: '?',
    hc: '?',
    ac: '?',
    we: '?',
    mo: '?',
    en: '?',
    se: 'se31',
    pu: 'pu31'
  }
]
