import React, { useEffect, useState } from 'react'
import { fetchSocket } from 'App/actions'
import { connect } from 'react-redux'
import { catTypes, genes } from 'utils'
import GeneTree from 'components/GeneTree'
import * as Styled from './CattributeMenu.style'

const CattributeMenu = ({ socket: { latestStats, socket } }) => {
  const [showCattributes, setShowCattributes] = useState(-1)
  return (
    <Styled.Div>
      {catTypes.map((cT, i) =>
        <Styled.MenuItem key={i} selected={showCattributes === i}
          onClick={() => showCattributes === i
            ? setShowCattributes(-1) 
            : setShowCattributes(i)
          }
        >{cT.readable}</Styled.MenuItem>
      )}
      {latestStats?.cattributes && catTypes.map((cT, i) => {
        if (i === showCattributes) {
          const { floors, floorTotals, totals } = latestStats.cattributes.find(({ pos }) => pos === i) 
          return (
            <GeneTree key={i} geneType={cT.param} {...{ genes, totals, floorTotals, floors }} />
          )
        }
      })}
    </Styled.Div>
  )
}

const mapStateToProps = ({ socket }) => ({ socket })
const connected = connect(mapStateToProps, { fetchSocket })(CattributeMenu)
export default connected
