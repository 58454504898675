import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 10px;
  margin-bottom: 30px;
  > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    b {
      font-size: 14px;
      font-weight: bold;
      display: block;
      margin-bottom: 2px;
    }
    table {
      margin: 4px 10px 20px;
    }
  }
`
