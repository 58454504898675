import styled from 'styled-components'

export const Div = styled.div`
  margin: 0 auto 60px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #CCC;
  padding-bottom: 36px;
  > div {
    width: 100%;
    > img {
      width: 100%;
    }
    @media (min-width: 700px) {
      justify-content: space-between;
      &:first-of-type {
        width: ${({ smaller }) => smaller ? '70%' : '50%'};
      }
      &:last-of-type {
        width: ${({ smaller }) => smaller ? '27%' : '47%'};
        margin-left: 3%;
      }
    }
  }
  img {
    border-radius: 10px;
  }
  > a {
    text-decoration: none;
    > img {
      width: 100%;
      border-radius: 10px;
    }
  }
  h2 {
    margin-bottom: 24px;
    text-align: left;
  }
  pre {
    line-height: 0;
    padding: 0 0 30px;
    text-align: left;
  }

  p {
    text-align: justify;
    line-height: 24px;
    margin-bottom: 20px;
    > a {
      color: rgba(255,116,228,1);
    }
  }

`
