import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import ChartContainer from 'components/ChartContainer'
import Kitty from 'components/Kitty'
import { fetchKitty } from 'components/Modal/actions'
import { API_URL } from 'utils'
import Pagination from 'components/Pagination'
import { catTypes, genes } from 'utils'
import * as Styled from './Cattribute.style'

export const isNumber = (number) => {
	return /^-?[\d.]+(?:e-?\d+)?$/.test(number)
}

const Cattribute = ({ modal: { kitty, kittyReady }, socket: { latestStats }, fetchKitty, handlePurchase }) => {
  const location = useLocation()
	const history = useHistory()
  const cattributes = catTypes.map(({ param }) => genes.map(g => g[param]))
  const flattributes = cattributes.flat()

  const [sales, setSales] = useState(undefined)
  const [floor, setFloor] = useState(undefined)
  const [search, setSearch] = useState(undefined)
  const [floorTotal, setFloorTotal] = useState(undefined)
  const { cattribute } = useParams()

  const getPage = (_params) => {
		let page = _params.get('page')
		return page && isNumber(page) ? Number(page) : 1
	}

  const prepSearchObject = useCallback(
		(_params) => {
			const _search = {}
			_search.page = getPage(_params)
			return _search
		},
		[]
	)

  useEffect(() => {
		if (!!search) {
			setSales(undefined)
			const apiUrlSales = `${API_URL}/sales?cattributes=[${cattribute}]`
      const apiUrlFloor = `${API_URL}/sales/floor?cattributes=[${cattribute}]&page=${search.page}`
			axios.get(apiUrlSales).then((response) => {
        setSales([...response.data.reverse()])
        axios.get(apiUrlFloor).then(response => {
          setFloor(response.data)
          if (flattributes.find(c => c === cattribute)) {
            cattributes.map((c, i) => {
                if (c.find(_c => _c === cattribute)) {
                    const gene = c.indexOf(cattribute)
                    const stats = latestStats?.cattributes.find(({ pos }) => pos === i)
                    setFloorTotal(stats?.floorTotals[`g${gene}`])
                }
            })
          }
        })
      })
		}
	}, [cattribute, search])

	useEffect(() => {
		const _params = new URLSearchParams(location.search)
		const _search = prepSearchObject(_params)
		setSearch(_search)
	}, [location.search, prepSearchObject])

  return (
    <Styled.Div>
      <h1>{cattribute}</h1>
      <Styled.Section>
        <ChartContainer title={`${cattribute} - Recent Sales`} kitties={sales} {...{ kitty, kittyReady, fetchKitty }} />
      </Styled.Section>
      <h3>{cattribute} Floor</h3>
      {floor &&
        <>
          <Styled.FloorDiv>
            {floor.map((kitty, key) =>
              <Kitty {...{ kitty, key, handlePurchase }} />
            )}
          </Styled.FloorDiv>
          {floorTotal &&
            <Pagination handlePageClick={({ selected }) => {
              selected === 0
                ? history.push(history.location.pathname)
                : history.push(`?page=${selected + 1}`)
            }} pageCount={Math.ceil(floorTotal / 24)} />
          }
        </>
        
      }
    </Styled.Div>
  )
}

const mapStateToProps = ({ modal, socket }) => ({ modal, socket })
const connected = connect(mapStateToProps, { fetchKitty })(Cattribute)
export default connected
