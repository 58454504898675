import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import logo from 'svg/logo.svg'
import './Logo.css'

const Logo = ({ socket: { blockHeader }}) => {
  const date = new Date().toString()
  const issueDate = moment(date).format('dddd Do MMMM YYYY')
  const number = blockHeader && blockHeader.length ? blockHeader[blockHeader.length - 1].number : '...'
  return (
    <div className={(window.location.pathname === '/') ? "logo logo-alt" : "logo logo-alt"}>
      <img src={logo} alt={'Kitty.News'} />
      <h1><Link to="/">{'Kitty.News'}</Link></h1>
      <h2>by <a href="https://kitty.international" target="_blank" rel="noopener noreferrer">Kitty.International</a></h2>
      <p>{issueDate /* } - Eth Block: {number.toLocaleString() */}</p>
      <img src={'https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/124.png'} style={{ width: '220px' }} />
      <p>kitty.news is down for some tlc but will be back up and running soon.</p>
      <p>in the meantime check out all the exciting new stuff at <a href={'https://kitty.family'}>kitty.family</a></p>
    </div>
  )
}

const mapStateToProps = ({ socket }) => ({ socket })
const connected = connect(mapStateToProps, null)(Logo)
export default connected
