import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  margin: 20px 2.5% 40px;
  justify-content: space-between;
`

export const MenuItem = styled.div`
  width: 48%;
  @media (min-width: 530px) {
    width: 32%;
  }
  @media (min-width: 800px) {
    width: 24%;
  }
  @media (min-width: 1180px) {
    width: 15%;
  }
  text-align: left;
  box-sizing: border-box;
  padding: 6px 10px;
  margin: 0 0 10px;
  border: 1px solid #000;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
`
