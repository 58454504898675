import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  width: 90%;
  margin: 40px auto;
  padding-bottom: 80px;
  max-width: 1000px;
  flex-direction: column;
  h2 {
    font-size: 28px;
    margin-bottom: 8px;
    margin-top: 20px;
  }
  h3 {
    &:last-of-type {
      margin-bottom: 30px;
    }
    a {
      color: rgb(239,82,209);
    }
  }
  h5, p {
    text-align: left;
    margin-bottom: 30px;
    b {
      font-weight: bold;
    }
  }
  h5 {
    margin-top: 30px;
    font-weight: bold;
  }
`

export const Div2Col = styled.div`
  display: flex;
  margin-bottom: 30px;
  > p {
    width: 20%;
    margin-right: 5%
  }
  > div {
    width: 75%;
    img {
      width: 100%;
      border: 1px solid #333;
    }
  }
`

export const Div2Col2 = styled.div`
  display: flex;
  margin-bottom: 30px;
  > div {
    width: 30%;
    img {
      width: 100%;
      border: 1px solid #333;
    }
    &:first-of-type {
      width: 65%;
      margin-right: 5%;
    }
  }
`
