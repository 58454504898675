import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import { interpolateLab } from 'd3-interpolate'

export default class Bars extends Component {
  constructor(props) {
    super(props)

    this.colorScale = scaleLinear()
      .domain([0, this.props.maxValue])
      .range(['#F3E5F5', '#7B1FA2'])
      .interpolate(interpolateLab)
  }

  render() {
    const { scales, margins, data, svgDimensions } = this.props
    const { xScale2, yScale2 } = scales
    const { height } = svgDimensions

    const dataLength = data.length

    const bars = (
      data.map((datum, i) => {
        return (
          <rect
            key={i}
            x={xScale2(datum.ts)}
            y={yScale2(datum.price)}
            height={height - margins.bottom - scales.yScale2(datum.price)}
            width={(100 / dataLength) * i}
            fill={this.colorScale(datum.price)}
          />
        )
      })
    )

    return (
      <g>{bars}</g>
    )
  }
}
