import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  align-items: center;
  justify-content: flex-start;
  height: 98%;
  margin-top: 0.5%;
  overflow: auto;
  position: relative;
  > div {
    padding: 20px 0;
    @media (min-width: 700px) {
      width: 80%;
      max-width: 620px;
    }
  }
  > p {
    font-size: 12px !important;
    line-height: 18px;
  }
`
