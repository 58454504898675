import React, { useEffect, useState } from 'react'
import * as Styled from './Volume.style'
import { formatDate, formatPrice } from 'utils'
import BarChart from 'components/Charts/BarChart'
import Big from 'big-number'
import Web3 from 'web3'

const color1 = '#177a25'
const color2 = 'rgba(16,45,145,1)'
const color3 = '#ff9088'
const color4 = '#4eb4f9'

const { BN, fromWei } = Web3.utils

const Volume = ({ data: _data, ethPrices }) => {
  const [sort, setSort] = useState(false)
  const [data, setData] = useState(false)
  const [chartData, setChartData] = useState(false)

  useEffect(() => {
    if (sort) {
      const sortType = sort.split('_')
      const d = sortType[1] === "asc"
        ? [...data.sort((a, b) => a[sortType[0]] - b[sortType[0]])]
        : [...data.sort((a, b) => b[sortType[0]] - a[sortType[0]])]
      setData(d)
    }
  },[sort])

  useEffect(() => {
    if (_data && ethPrices) {
      let SaleVolumeUSDTotal = 0
      let SireVolumeUSDTotal = 0
      const __data = _data.map(_d => {
        try {
          const { SaleVolume, SireVolume, timestamp } = _d
          if (ethPrices.find(({ ts }) => ts === timestamp)) {
            const { ethprice } = ethPrices.find(({ ts }) => ts === timestamp)
            const SaleVolumeUSD = (Number(fromWei(SaleVolume)) * ethprice).toFixed(2)
            const SireVolumeUSD = (Number(fromWei(SireVolume)) * ethprice).toFixed(2)
            SaleVolumeUSDTotal = Number(SaleVolumeUSDTotal) + Number(SaleVolumeUSD)
            SireVolumeUSDTotal = Number(SireVolumeUSDTotal) + Number(SireVolumeUSD)
            return { 
              ethprice,
              SaleVolumeUSD, SaleVolumeUSDTotal,
              SireVolumeUSD, SireVolumeUSDTotal,
              ..._d
            }
          } else {
            return { 
              ethprice: '0',
              SaleVolumeUSD: 0, SaleVolumeUSDTotal: 0,
              SireVolumeUSD: 0, SireVolumeUSDTotal: 0,
              ..._d
            }
          }
        } catch (e) {
          console.log(e)
        }
        
       
      })
      setData([...__data])
      setChartData([...__data])
    }
  }, [_data, ethPrices])

  const getPercentageChange = (a, b) => {
    const percentage = (((100 / formatPrice(b)) * formatPrice(a)) - 100).toFixed(2)
    const addPlus = percentage > 0 ? '+' : ''
    // TODO: not working?
   //return <b style={{ color: percentage > 0 ? '#66CDAA' : '#F00' }}>{addPlus + percentage + "%"}</b>
  }

  const getAverage = (d, type) => {
    let total = Big(0)
    d.map(_d => { 
      total = total.plus(Big(_d[type])) 
    })
    const average = total.div(d.length).toString()
    return average
  }

  const getAverageFloat = (d, type) => {
    let total = 0
    d.map(_d => { total = total + parseFloat(_d[type]) })
    const format_average = parseFloat((total / d.length).toFixed(2)).toLocaleString()
    return format_average.split('.')[1].length === 1
      ? `$${format_average}0`
      : `$${format_average}`

  }

  const convertPrice = (a, timestamp) => {
    if (ethPrices.find(({ ts }) => ts === timestamp) !== undefined) {
      const { ethprice } = ethPrices.find(({ ts }) => ts === timestamp)
      return parseFloat(parseFloat(ethprice * a).toFixed(2)).toLocaleString()
    }
  }

  return (
    <Styled.Main>
      {chartData &&
        <div style={{ width: '100%' }}>
          <BarChart dataFeed={chartData} {...{ color1, color2, color3, color4 }} />
        </div>
      }
      <Styled.KeyDiv {...{ color1, color2, color3, color4 }}>
        <div><span></span> Volume ($)</div>
        <div><span></span> Volume (Eth)</div>
        <div><span></span> Births</div>
        <div><span></span> Eth Price ($)</div>
      </Styled.KeyDiv>
      <table>
        {data &&
          <tr>
            <td>Daily Averages:</td>
            <td>{parseInt(getAverage(data, 'Birth')).toLocaleString()}</td>
            <td>{getAverage(data, 'Sales')}</td>
            <td>{/* Ξ{formatPrice(getAverage(data, 'SaleVolume'), 3)} */}</td>
            <td>{/* getAverageFloat(data, 'volumeUSD') */}</td>
            <td>{/* getAverageFloat(data, 'ethprice') */}</td>
            <td colspan={4} />
          </tr>
        }
        <tr>
          <td onClick={() => sort === 'timestamp_asc' ? setSort('timestamp_desc') : setSort('timestamp_asc')}>
            <span>Date</span>
          </td>
          <td onClick={() => sort === 'Birth_asc' ? setSort('Birth_desc') : setSort('Birth_asc')}>
            <span>Births</span>
          </td>
          <td>All Births</td>
          <td onClick={() => sort === 'SaleSuccessful_asc' ? setSort('SaleSuccessful_desc') : setSort('SaleSuccessful_asc')}>
            <span>Sales</span>
          </td>
          <td onClick={() => sort === 'SaleVolume_asc' ? setSort('SaleVolume_desc') : setSort('SaleVolume_asc')}>
            <span>Sales Vol. Ξ</span>
          </td>
          <td onClick={() => sort === 'SaleVolumeUSD_asc' ? setSort('SaleVolumeUSD_desc') : setSort('SaleVolumeUSD_asc')}>
            <span>Sales Vol. $</span>
          </td>
          <td onClick={() => sort === 'ethprice_asc' ? setSort('ethprice_desc') : setSort('ethprice_asc')}>
            <span>Eth Price</span>
          </td>
          <td>Total Sales</td>
          <td>Total Sales Ξ</td>
          <td>Total Sales $</td> 
          <td>Total Sires</td>
          <td>Total Sires Ξ</td>
          <td>Total Sires $</td>
        </tr>
        {data && [...data].reverse().map((_data, i) => {
          const { 
            Birth, BirthTotal,
            SaleSuccessful, SaleSuccessfulTotal, SaleVolume, SaleVolumeUSD, SaleVolumeTotal, SaleVolumeUSDTotal,
            SireSuccessful, SireSuccessfulTotal, SireVolume, SireVolumeTotal, SireVolumeUSDTotal,
            /* volumeUSD, totalVolumeUSD, ethprice, */ timestamp
          } = _data
          return (
            <tr key={i}>
              <td>{formatDate(timestamp)}</td>
              <td>{Birth.toLocaleString()}</td>
              <td>{BirthTotal.toLocaleString()}</td>
              <td>{SaleSuccessful.toLocaleString()}</td>
              <td>{formatPrice(SaleVolume)} <b>{i < data.length -1 && getPercentageChange(SaleVolume, data[i + 1].SaleVolume)}</b></td>
              <td>${SaleVolumeUSD}</td>
              <td>${ethPrices.find(({ ts }) => ts === timestamp) !== undefined
                ? parseFloat(ethPrices.find(({ ts }) => ts === timestamp).ethprice).toFixed(2)
                : '-'
              }</td>
              <td>{SaleSuccessfulTotal.toLocaleString()}</td>
              <td>{formatPrice(SaleVolumeTotal).toLocaleString()}</td>
              <td>${parseFloat(parseFloat(SaleVolumeUSDTotal).toFixed(2)).toLocaleString()}</td>
              <td>{SireSuccessfulTotal.toLocaleString()}</td>
              <td>{formatPrice(SireVolumeTotal)}</td>
              <td>${parseFloat(parseFloat(SireVolumeUSDTotal).toFixed(2)).toLocaleString()}</td>
            </tr>
          )
        })}
      </table>
    </Styled.Main>
  )
}

export default Volume
