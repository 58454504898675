import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatPrice } from 'utils';
import { fetchSocket } from 'App/actions'
import loading from 'svg/loading.svg'
import * as Styled from "./MarketSummary.style"

const MarketSummary = ({
  socket: { socket, latestStats },
  fetchSocket
}) => {

  useEffect(() => {
    !socket ? fetchSocket() : socket.emit('ck-floorUpdate')
  },[socket])


  const getFloorPrice = floor => {
    if (floor.floor.length) {
      const { currentPrice } = floor.floor[0]
      if (!currentPrice) { // must be CK API
        const { auction: { current_price } } = floor.floor[0]
        return formatPrice(current_price)
      }
      return formatPrice(currentPrice)
    } else {
      console.log(floor)
    }
  }

  return (
    <Styled.Div>
      {latestStats
        ?
          <p>
            {latestStats.sales && (
              <>
                Sales: <Link to="/markets/sales">{getFloorPrice(latestStats.sales)}</Link>
              </>
            )}
            {latestStats.sires && (
              <>
                Sires: <Link to="/markets/sires">{getFloorPrice(latestStats.sires)}</Link>
              </>
            )}
            {latestStats.gen0 && (
              <>
                Gen0: <Link to="/markets/gen0">{getFloorPrice(latestStats.gen0)}</Link>
              </>
            )}
            {latestStats['2017'] && (
              <>
                2017: <Link to="/markets/2017">{getFloorPrice(latestStats['2017'])}</Link>
              </>
            )}
            <br />
            {latestStats.diamonds && (
              <>
                Diamonds: <Link to="/markets/diamonds">{getFloorPrice(latestStats.diamonds)}</Link>
              </>
            )}
            {latestStats.gilded && (
              <>
                Gilded: <Link to="/markets/gilded">{getFloorPrice(latestStats.gilded)}</Link>
              </>
            )}
            {latestStats.amethyst && (
              <>
                Amethyst: <Link to="/markets/amethyst">{getFloorPrice(latestStats.amethyst)}</Link>
              </>
            )}
            {latestStats.lapis && (
              <>
                Lapis: <Link to="/markets/lapis">{getFloorPrice(latestStats.lapis)}</Link>
              </>
            )}
            <br />
            {latestStats.founders && (
              <>
                Founders: <Link to="/markets/founders">{getFloorPrice(latestStats.founders)}</Link>
              </>
            )}
            {latestStats.day1 && (
              <>
                Day1: <Link to="/markets/day1">{getFloorPrice(latestStats.day1)}</Link>
              </>
            )}
            {latestStats.exclusive && (
              <>
                Exclusive: <Link to="/markets/exclusive">{getFloorPrice(latestStats.exclusive)}</Link>
              </>
            )}
            {latestStats.fancy && (
              <>
                Fancy: <Link to="/markets/fancy">{getFloorPrice(latestStats.fancy)}</Link>
              </>
            )}
            {latestStats.shinyfancy && (
              <>
                Shiny Fancy: <Link to="/markets/shinyfancy">{getFloorPrice(latestStats.shinyfancy)}</Link>
              </>
            )}
          </p>
          // <p>Selling: <Link to="/markets/sales">{'>= Ξ'+formatPrice(salesFloor,3)}</Link>
          //    Siring: <Link to="/markets/sires">{'>= Ξ'+formatPrice(siresFloor,3)}</Link>
          //    Gen0 ({gen0Total >= 2000 ? '>2000' : gen0Total}): <Link to="/markets/gen0">{'>= Ξ'+formatPrice(gen0Floor,3)}</Link><br />
          //    Diamond ({diamondTotal}): <Link to="/markets/diamond">{'>= Ξ'+formatPrice(diamondFloor,3)}</Link>
          //    Gilded ({gildedTotal}): <Link to="/markets/gilded">{'>= Ξ'+formatPrice(gildedFloor,3)}</Link>
          //    Amethyst ({amethystTotal >= 2000 ? '>2000' : amethystTotal}): <Link to="/markets/amethyst">{'>= Ξ'+formatPrice(amethystFloor,3)}</Link>
          //    Lapis ({lapisTotal >= 2000 ? '>2000' : lapisTotal}): <Link to="/markets/lapis">{'>= Ξ'+formatPrice(lapisFloor,3)}</Link>
          //    <br />
          //    Founders ({foundersTotal}): <Link to="/markets/founders">{'>= Ξ'+formatPrice(foundersFloor,3)}</Link>
          //    Day One ({day1Total}): <Link to="/markets/day1">{'>= Ξ'+formatPrice(day1Floor,3)}</Link>
          //    Exclusives ({exclusiveTotal}): <Link to="/markets/exclusive">{'>= Ξ'+formatPrice(exclusiveFloor,3)}</Link>
          //    Fancies: ({fancyTotal >= 2000 ? '>2000' : fancyTotal}) <Link to="/markets/fancy">{'>= Ξ'+formatPrice(fancyFloor,3)}</Link>
          // </p>
        : <img src={loading} alt={''} />
      }
    </Styled.Div>
  )
}

const mapStateToProps = ({ socket }) => ({ socket })
const connected = connect(mapStateToProps, { fetchSocket })(MarketSummary)
export default connected
