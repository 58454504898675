import React, { Component } from 'react'
//import { scaleLinear } from 'd3-scale'
//import { interpolateLab } from 'd3-interpolate'
import { curveCardinal } from 'd3'
import { line as d3Line } from 'd3-shape'

export default class Lines extends Component {
  constructor(props) {
    super(props)
    this.key = 0
  }

  getKey() {
    return this.key++
  }

  render() {
    const { scales, data, color, selectX, selectY, onClick, dataForLabels, dotRadius } = this.props
    const { xScale, yScale } = scales

    const selectScaledX = data => xScale(selectX(data));
    const selectScaledY = data => yScale(selectY(data));
    const sparkLine = d3Line()
      .x(selectScaledX)
      .y(selectScaledY)
      //.curve(curveCardinal);

    const linePath = sparkLine(data);
    const lines = (
      <path d={linePath} stroke={color} strokeWidth="3" fill="none" />
    )

    const circlePoints = data.map(datum => ({
      x: selectScaledX(datum),
      y: selectScaledY(datum),
      curve: curveCardinal
    }))
    const circles = (
      <g className="scatter">
        {circlePoints.map((circlePoint, i = -1) => {
          i++
          return (
              <circle
                cx={circlePoint.x}
                cy={circlePoint.y}
                key={this.getKey()}
                r={dotRadius}
                fill={color}
                onClick={() => onClick(dataForLabels[(i-1)])}
              />
          )
        })}
      </g>
    )

    return (
      <g>
        <g>{lines}</g>
        <g>{circles}</g>
      </g>
    )
  }
}
