import axios from 'axios'
import { API_URL } from 'utils'
export const REQUEST_KITTY = 'REQUEST_KITTY'
export const RECEIVE_KITTY = 'RECEIVE_KITTY'
export const ERROR_KITTY = 'ERROR_KITTY'

let baseRequest = axios.create({ baseURL: API_URL })

export function fetchKitty(kitty) {
  return async (dispatch) => {
    dispatch({ type: REQUEST_KITTY })
    return baseRequest.get('kitties?id='+kitty.tokenId).then(result => {
      dispatch(receiveKitty(result.data[0]))
    }).catch(err => error(err))
  }
}

function receiveKitty(payload) {
  return {
    type: RECEIVE_KITTY,
    payload,
  }
}

function error(error) {
  return {
    type: ERROR_KITTY,
    payload: error
  }
}
