import styled from 'styled-components'

export const Kitties = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  > div {
    width: 5%;
    cursor: pointer;
    background-size: 192%;
    background-position: 42% 25%;
    &:hover {
      opacity: 0.7;
    }
    &:before {
      content:"";
      display:block;
      margin-top:100%;
   }
  }
`

export const ChartInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  margin: 10px 1% 40px;
  > div {
    width: 40%;
    &:first-of-type {
      text-align: left;
      width: 30%;
    }
    &:last-of-type {
      text-align: right;
      width: 30%;
    }
    span {
      display: block;
      margin-bottom: 4px;
      b {
        font-weight: bold;
      }
    }
  }
`
