import React, { useEffect, useState } from 'react'
import { formatPrice, formatDate } from 'utils'
import Web3 from 'web3'
import * as Styled from './Yesterday.style'

const { fromWei } = Web3.utils

const Yesterday = ({ volume }) => {
  if (volume.length === 0) {
    return null
  }
  const { Birth, SaleVolume, SaleVolumeTotal, SireVolume, SireVolumeTotal, timestamp } =  volume[volume.length - 1]
  
  return (
    <Styled.Div>
      <h3 >{'Yesterday...'}</h3>
      <div>
        <h3>{formatDate(timestamp).split('12:00am')[0]}<span dangerouslySetInnerHTML={{ __html: `12:00am - 11:59pm (UTC)` }} /></h3>
        <h3>
          {'Born: '}{Birth.toLocaleString()}
          <span>{'All time:'} {getTotal(volume, 'Birth').toLocaleString()}</span>
        </h3>
        <h3>
          {'Sale Volume: '}Ξ{parseFloat(fromWei(SaleVolume)).toFixed(3)}
          <span>{'All time: '}Ξ{parseFloat(fromWei(SaleVolumeTotal)).toFixed(3)}</span>
        </h3>
        <h3>
          {'Sire Volume: '}Ξ{parseFloat(fromWei(SireVolume)).toFixed(3)}
          <span>{'All time: '}Ξ{parseFloat(fromWei(SireVolumeTotal)).toFixed(3)}</span>
        </h3>
        <Styled.Link to={'/volume'}>See all volume</Styled.Link>
      </div>
    </Styled.Div>
  )
}

export default Yesterday


const getTotal = (v, type) => {
  let t = 0
  v.map(_v => { t = t + _v[type] })
  return t
}