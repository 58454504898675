import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import axios from 'axios'
import Cookies from 'js-cookie'
import AppView from './AppView'
import Connect from 'components/Connect'
import ScrollTop from 'components/ScrollTop'
import CryptoKittiesContract from 'contracts/cryptokitties'
import { API_URL } from 'utils'

const web3 = new Web3(Web3.givenProvider)

const App = () => {
  const [account, setAccount] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(Cookies.get('kitty_token') || '')
  const [auctionContract, setAuctionContract] = useState(false)

  useEffect(() => {
    async function checkToken() {
      if (token) {
        try {
          const { data } = await axios.post(API_URL + '/auth/check-token', { token });
          setLoggedIn(data.valid);
          // const networkId = await web3.eth.net.getId()
          const instance = new web3.eth.Contract(
            CryptoKittiesContract.Sale.abi,
            CryptoKittiesContract.Sale.addr
          )
          setAuctionContract(instance);

          const latestBlockNumber = await web3.eth.getBlockNumber();
          const latestBlock = await web3.eth.getBlock(latestBlockNumber);
          const latestTimestamp = latestBlock.timestamp;
          const latestDate = new Date(latestTimestamp * 1000).toLocaleString();
        } catch (error) {
          console.error(error);
        }
      }
    }

    checkToken();
  }, [token]);

  useEffect(() => {
    async function getAccounts() {
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
    }

    if (window.ethereum) {
      window.ethereum.enable().then(getAccounts);
    }
  }, []);

  async function handleSignMessage() {
    if (window.ethereum) {
      const message = "Sign this message to authenticate.";
      const data = {
        message,
        timestamp: Date.now(),
      };
      const signature = await web3.eth.personal.sign(
        JSON.stringify(data),
        account
      );
      axios.post(API_URL + '/auth', {
        address: account,
        signature,
        data,
      }).then(async (response) => {
        Cookies.set('kitty_token', response.data.token, { expires: 7 });
        setLoggedIn(true);
        setToken(response.data.token);
        const instance = new web3.eth.Contract(
          CryptoKittiesContract.Sale.abi,
          CryptoKittiesContract.Sale.addr
        )
        setAuctionContract(instance);
        return
      }).catch((error) => console.error(error));
    } else {
      console.log("No Ethereum provider detected.");
    }
  }

  async function logout() {
    if (loggedIn) {
      axios.post(API_URL + '/auth/logout', { token }
      ).then(async data => {
        setLoggedIn(false)
        Cookies.remove('bb_token');
        setToken('');
      }).catch((error) => console.error(error));
    }
  }

  return (
    <>
      {/* <Connect {...{ account, loggedIn, logout, handleSignMessage }} />
      <ScrollTop /> */}
      <AppView account={account && loggedIn ? account.toLowerCase() : null} {...{ auctionContract }} />
    </>
  )
}

export default App
