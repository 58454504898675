import styled from 'styled-components'

export const Div = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(20,20,20,0.8);
  top: 0;
  left: 0;
  z-index: 10000000000000000000;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > span {
    position: absolute;
    color: #000;
    @media (min-width: 700px) {
      position: fixed;
      color: #FFF;
    }
    right: 20px;
    top: 8px;
    font-weight: bold;
    font-size: 36px;
    cursor: pointer;
    z-index: 10000000000000000001;
    &:hover {
      opacity: 0.8;
    }
  }
`
