import styled from 'styled-components'

export const Div = styled.div`
  display: flex;
  border: 1px dotted #444;
  margin: 0 5% 40px;
  padding: 20px 5% 10px;
  > div {
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      align-items: flex-start;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      > img {
        width: 24px;
      }
      h3 {
        padding: 4px 0 0 10px;
        span {
          display: inline-block;
          color: rgb(239, 82, 209);
          margin-left: 4px;
        }
      }
    }
  }
`
