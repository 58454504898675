import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Route } from 'react-router'
import Logo from 'components/Logo'
import MarketSummary from 'components/MarketSummary'
import Total from 'components/Total'
import EthPrice from 'components/EthPrice'
import Cattribute from 'pages/Cattribute'
import CattributeMenu from 'components/CattributeMenu'
import GensMenu from 'components/GensMenu'
import Article from 'pages/Article'
import Markets from 'pages/Markets'
import Homepage from 'pages/Homepage'
import Volume from 'pages/Volume'
import { API_URL } from 'utils'
// import Search from 'pages/Search'
import * as Styled from "./AppView.style"

const AppView = ({ account, auctionContract }) => {
  const [latestKitties, setLatestKitties] = useState(undefined)
  const [volume, setVolume] = useState([])
  const [volumeToday, setVolumeToday] = useState(undefined)
  const [ethPrices, setEthPrices] = useState(undefined)

  useEffect(() => {
    axios.get(API_URL + '/kitties').then(({ data }) => {
      setLatestKitties(data.kitties)
    }).catch(e => console.log(e))
  }, [])

  useEffect(() => {
    axios.get(API_URL + '/daily').then(({ data }) => {
      setVolume(data)
    })
  }, [])

  useEffect(() => {
    axios.get(API_URL + '/daily/today').then(({ data }) => {
      setVolumeToday(data)
    })
  }, [])

  useEffect(() => {
    axios.get(API_URL + '/ethPrice').then(({ data }) => {
      setEthPrices(data)
    })
  }, [])

  const handlePurchase = async (id, price) => {
    try {
        await auctionContract.methods.bid(id).send({ from: account, value: price })
    } catch (e) {
        console.log(e)
    }
  }

  const handleSire = (id, price) => console.log('Siring currently not operational. Check back soon.')

  return (
    <Styled.Div>
      {/* <EthPrice />
      <Total total={latestKitties && latestKitties[0].tokenId} /> */}
      <Logo />
      {/* <MarketSummary />
      {volume && !!volume.length && volumeToday && <GensMenu { ...{ volume, volumeToday }} />}
      <CattributeMenu />
      <Route exact path="/" render={() => latestKitties && <Homepage {...{ account, volume, latestKitties, handlePurchase }} />} />
      <Route exact path="/article" render={() => <Article {...{ handlePurchase }} />} />
      <Route exact path="/article/:article" render={props => <Article {...props} {...{ handlePurchase }} />} />
      <Route exact path="/markets/:report_type" render={props => <Markets {...props} {...{ handlePurchase }} />} />
      <Route exact path="/markets/:report_type/:page" render={props => <Markets {...props} {...{ handlePurchase }} />} />
      <Route exact path="/cattribute/:cattribute" render={props => <Cattribute {...props} {...{ handlePurchase }} />} />
      <Route exact path="/volume" render={props => <Volume {...props} data={[...volume].reverse()} {...{ ethPrices }} />} /> */}
      {/* <Route exact path="/search" component={Search} /> */}
    </Styled.Div>
  )
}

export default AppView
