import axios from 'axios'
import { API_URL } from 'utils'

export const FLOOR_BY_TYPE_REQUEST = 'FLOOR_BY_TYPE_REQUEST'
export const FLOOR_BY_TYPE_RECEIVED = 'FLOOR_BY_TYPE_RECEIVED'
export const FLOOR_BY_TYPE_ERROR = 'FLOOR_BY_TYPE_ERROR'

const floorQueries = [
  { type: 'sales', query: 'kitties?limit=20&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'sires', query: 'kitties?limit=20&include=sire&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'diamond', query: 'kitties?limit=20&include=sale&search=mewtation:diamond&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'gilded', query: 'kitties?limit=20&include=sale&search=mewtation:gilded&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'amethyst', query: 'kitties?limit=20&include=sale&search=mewtation:amethyst&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'lapis', query: 'kitties?limit=20&include=sale&search=mewtation:lapis&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'founders', query: 'kitties?limit=20&search=id:2-99&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'gen0', query: 'kitties?limit=20&search=gen:0&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'day1', query: 'kitties?limit=20&search=id:1000-3365+type:normal&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'fancy', query: 'kitties?limit=20&search=type:fancy&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'exclusive', query: 'kitties?limit=20&search=type:exclusive&include=sale&orderDirection=asc&authenticated=false&orderBy=current_price&total=true' },
  { type: 'gen100', query: 'kitties?limit=20&search=gen:100&orderDirection=desc&authenticated=false&orderBy=age&total=true' },
  { type: 'cattributes', query: 'cattributes' },
  { type: 'colors', query: 'colors' }
]

let baseRequest = axios.create({ baseURL: API_URL })

export const getFloorByType = (type, page) => dispatch => {
  dispatch({ type: FLOOR_BY_TYPE_REQUEST })
  if (floorQueries.find(fQ => fQ.type === type)) {
    const { query } = floorQueries.find(fQ => fQ.type === type)
    const offset = Math.sign(page) === 1
      ? (page - 1) * 20
      : 0
    baseRequest.get(`v2/${query}&offset=${offset}`)
      .then(({ data }) => {
        dispatch(receiveFloorByType(data))
      })
      .catch(error => {
        dispatch({ type: FLOOR_BY_TYPE_ERROR })
      })
  }
}

const receiveFloorByType = payload => {
  return {
    type: FLOOR_BY_TYPE_RECEIVED,
    payload
  }
}
