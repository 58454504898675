import { catTypes, genes } from 'utils'
import * as Styled from './KittyGenes.style'
var decToBinary = require('dec-to-binary')

const KittyGenes = ({ geneString, kitty }) => {
  let geneToBinary = decToBinary.decimal(geneString).toString()
  const addZeros = 240 - geneToBinary.length
  for (let i = 0; i < addZeros; i ++) {
    geneToBinary = "0"+geneToBinary
  }
  const geneBlocks = [...geneToBinary.match(/.{1,5}/g).reverse()]
  return (
    <Styled.Div>
      {geneToBinary.length !== 240 && geneToBinary.length}
      <div>
        {geneBlocks.length === 48 && geneToBinary.length === 240 && geneBlocks.map((gB, i) => i % 4 === 0 && (
          kitty.genes
            ? <div key={i}>
                <b>{genes.find(g => g.binary === gB)[catTypes[(i / 4)].param]
                  ? genes.find(g => g.binary === gB)[catTypes[(i / 4)].param]
                  : '-'
                }</b>
                {catTypes[(i / 4)].readable}
                <table>
                  <tr style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                    <td style={{ padding: '3px 3px', backgroundColor: '#cdf5d4' }}>{genes.find(g => g.binary === gB).kai}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ddc4ff' }}>{genes.find(g => g.binary === geneBlocks[i + 1]).kai}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ede2f5' }}>{genes.find(g => g.binary === geneBlocks[i + 2]).kai}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#f6f1fa' }}>{genes.find(g => g.binary === geneBlocks[i + 3]).kai}</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '3px 3px', backgroundColor: '#cdf5d4' }}>{gB}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ddc4ff' }}>{geneBlocks[i + 1]}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ede2f5' }}>{geneBlocks[i + 2]}</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#f6f1fa' }}>{geneBlocks[i + 3]}</td>
                  </tr>
                </table>
              </div>
            : <div key={i}>
                <b>-</b>
                <table>
                  <tr style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                    <td style={{ padding: '3px 3px', backgroundColor: '#cdf5d4' }}>-</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ddc4ff' }}>-</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ede2f5' }}>-</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#f6f1fa' }}>-</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '3px 3px', backgroundColor: '#cdf5d4' }}>-----</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ddc4ff' }}>-----</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#ede2f5' }}>-----</td>
                    <td style={{ padding: '3px 3px', backgroundColor: '#f6f1fa' }}>-----</td>
                  </tr>
                </table>
              </div>
          )
        )}
      </div>
    </Styled.Div>
  )
}


export default KittyGenes
