import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

export const Div = styled(ReactPaginate)`
    width: 90%;
    display: flex;
    justify-content: space-between;
    @media (min-width: 780px) {
        width: 60%;
    }
    > li {
        > a {
            cursor: pointer;
            &:hover {
                text-decoration: none;
            }
        }
        &.disabled {
            opacity: 0.4;
            > a {
                cursor: default;
            }
        }
    }
`