import React, { useEffect, useState } from 'react'
import Chart from 'components/Charts/Chart'
import loader from 'img/loader.gif'
import KittyDetail from 'components/KittyDetail'
import Modal from 'components/Modal'
import * as Styled from './ChartContainer.style'

const ChartContainer = ({ title, kitties }) => {
  const [modalKitty, setModalKitty] = useState(undefined)
  
  const getInfo = kitty => {
    document.body.style.overflow = 'hidden'
    setModalKitty(kitty)
  }

  const onClose = () => {
    document.body.style.overflow = 'auto'
    setModalKitty(undefined)
  }

  return (
    <Styled.Div>
      {modalKitty &&
        <Modal { ...{ onClose }}>
          <KittyDetail kitty={modalKitty} />
        </Modal>
      }
      <h3>{title}</h3>
		  {kitties
        ? <Chart dataFeed={kitties} {...{ getInfo }} />
        : <img src={loader} alt={''} />
      }
    </Styled.Div>
  )
}

export default ChartContainer
