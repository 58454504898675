import styled from "styled-components"

export const Div = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 48px;
  > div {
    > div {
      width: 90%;
      margin-left: 5%;
      position: relative;
      box-sizing: border-box;
    }
  }
  > p {
    &:first-of-type {
      font-weight: bold;
    }
    margin-bottom: 12px;
  }
`
