import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import ResponsiveWrapper from './ResponsiveWrapper'
import { formatDateTime, formatPrice, getTimeLength } from 'utils'
import Axes from './Axes'
import Lines from './Lines'
import * as Styled from './Chart.style'
import { BN, fromWei } from 'web3-utils'
import { genes } from 'utils'

const getTotalVolume = (data) => {
  let total = new BN('0')
  data.map(d => {
    total = total.add(new BN(d.totalPrice))
  })
  return formatPrice(total.toString().padStart(25, '0'))
}

class Chart extends Component {
  constructor(props) {
    super(props)
    this.xScale = scaleLinear()
    this.yScale = scaleLinear()
  }

  render() {
    const { parentWidth, dataFeed, getInfo } = this.props
    const margins = { top: 10, right: 10, bottom: 10, left: 10 }
    const svgDimensions = {
      width: Math.max(parentWidth, 300),
      height: 300
    };

    let maxXValues = [],
        maxYValues = [],
        dataForChart = []

    dataFeed.map((d, i = 1) => {
      let q = {}
      maxXValues.push(i);
      q.blockNumber = i
      q.totalPrice = fromWei(d.totalPrice)
      maxYValues.push(q.totalPrice);
      dataForChart.push(q)
      return i++
    });

    const maxXValue = Math.max(...maxXValues)
    const minXValue = Math.min(...maxXValues)
    const maxYValue = Math.max(...maxYValues)

    let xScale = this.xScale
        .domain([minXValue, maxXValue])
        .range([margins.left, svgDimensions.width - margins.right])
    const yScale = this.yScale
        .domain([0, maxYValue])
        .range([svgDimensions.height - margins.bottom, margins.top])

    let nLines = 5

    const total = getTotalVolume(dataFeed)

    return (
      <>
        <svg width={svgDimensions.width} height={svgDimensions.height}>
          <Axes
              scales={{ xScale, yScale }}
              margins={margins}
              svgDimensions={svgDimensions}
          />
          {[...Array(nLines)].map((v, i)=>
            <rect key={i} width={svgDimensions.width} height={svgDimensions.height / nLines} style={{ fill: (i%2 ? 'rgb(255,255,255)' : 'rgb(247,247,247)') }} y={(svgDimensions.height / nLines) * i} />
          )}
          <Lines
            scales={{ xScale, yScale }}
            { ...{ margins, svgDimensions }}
            width={'10px'}
            data={dataForChart}
            dataForLabels={dataFeed}
            color={'#333'}
            selectX={dataForChart => dataForChart.blockNumber}
            selectY={dataForChart => dataForChart.totalPrice}
            onClick={kitty => getInfo(kitty)}
            dotRadius={4.5}
          />
        </svg>
        <Styled.Kitties>
          {dataFeed.map((kitty, i) => 
            <div
              key={i}
              className={`${genes[kitty.g8].ec}`}
              onClick={() => getInfo(kitty)}
              style={{ backgroundImage: `
                url('https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.png'),
                url('https://img.cryptokitties.co/0x06012c8cf97bead5deae237070f9587f8e7a266d/${kitty.tokenId}.svg')`, 

              }}
            />
          )}

        </Styled.Kitties>
        {dataFeed.length &&
          <Styled.ChartInfo>
            <div>
              <span>BN: {dataFeed[0].blockNumber}</span>
              <span>{formatDateTime(dataFeed[0].ended)}</span>
            </div>
            <div>
              <span>- {getTimeLength(dataFeed[dataFeed.length - 1].ended, dataFeed[0].ended).split(' ')[0]} {getTimeLength(dataFeed[dataFeed.length - 1].ended, dataFeed[0].ended).split(' ')[1]} -</span>
              <span>Total: <b>{total}</b></span>
            </div>
            <div>
              <span>BN: {dataFeed[dataFeed.length - 1].blockNumber}</span>
              <span>{formatDateTime(dataFeed[dataFeed.length - 1].ended)}</span>
            </div>
          </Styled.ChartInfo>
        }
      </>
    )
  }
}

export default ResponsiveWrapper(Chart)
