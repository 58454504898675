import styled from "styled-components"

export const Div = styled.div`
  width: 95%;
  margin-left: 2.5%;
  background-color: rgba(255,255,255,0.4);
  padding: 8px 20px;
  box-sizing: border-box;
  border: 1px solid #CCC;
  @media screen and (min-width: 1470px) {
    margin: 0 auto 20px;
  }

  p {
    font-size: 14px;
    a {
      font-weight: bold;
      display: inline-block;
      margin-right: 20px;
      text-decoration: none;
      color: #333;
    }
  }

  img {
    margin: 20px;
  }
`
