import styled from "styled-components";

export const Div = styled.div`
  text-align: center;
  color: #333;
  position: relative;
  z-index: 0;
  font-size: 14px;
  hr {
    margin: 0 2% 10px;
    opacity: 0.2
  }
  h1,h2,h3 {
    font-family: "bungee",sans-serif;
    font-style: normal;
    font-weight: 400;
    cursor: default;
  }
  h4 {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    span {
      font-weight: normal;
    }
  }
  a {
    color: #333;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
`
