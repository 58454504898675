import Kitty from 'components/Kitty'
import KittyGenes from 'components/KittyGenes'
import * as Styled from './KittyDetail.style'

const KittyDetail = ({ kitty }) => {
  return (
    <Styled.Div>
      <Kitty {... { kitty }} />
      {kitty.gen > 0 && 
        <p style={{ marginBottom: '20px'}}>Matron: {kitty.matronId} Sire: {kitty.sireId}</p>
      }
      {kitty.winner && <>
        <p>Buyer: {kitty.winner}</p>
        <p>Seller: {kitty.owner}</p>
      </>}
      <KittyGenes geneString={kitty.genes} {...{ kitty}} />
    </Styled.Div>
  )
}
  

export default KittyDetail
