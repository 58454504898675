import styled from 'styled-components';

export const Span = styled.div`
  padding: 0.2rem 0;
  color: ${({ highlight }) => (highlight ? 'rgba(255, 116, 228, 1)' : '#444')};
  font-weight: ${({ highlight }) => highlight ? 'bold' : 'normal'};
`;

export const Div = styled.div`
  width: 98%;
  margin-top: 10px;
  > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-bottom: 3px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      right: -34px;
      text-align: left;
      min-width: 30px;
      font-size: 1.2rem;
      opacity: 0.8;
    }
    &:after {
      position: absolute;
      right: -40px;
      text-align: left;
      min-width: 30px;
      font-size: 14px;
      opacity: 0.8;
      font-weight: bold;
    }
    > div {
      width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      > a {
        font-weight: bold;
        color: #333;
        > span {
          display: block;
          font-size: 11px;
          margin-top: 2px;
          font-weight: normal;
          &:nth-of-type(1) {
            font-size: 13px;
            font-weight: bold;
            color: rgba(255, 116, 228, 1);
          }

        }
        &:hover {
          text-decoration: none;
          > span {
            text-decoration: underline;
          }
        }
      }

    }
    &:nth-of-type(1) {
      background-color: rgba(255, 116, 228, 0.3);
      padding: 6px 0;
      &:after {
        content: 'M4';
      }
    }
    &:nth-of-type(2) {
      background-color: rgba(255, 116, 228, 0.22);
      padding: 4px 0;
      &:after {
        content: 'M3';
      }
    }
    &:nth-of-type(3) {
      background-color: rgba(255, 116, 228, 0.15);
      padding: 4px 0;
      &:after {
        content: 'M2';
      }
      > div {
        width: 12.5%;
      }
    }
    &:nth-of-type(4) {
      background-color: rgba(255, 116, 228, 0.1);
      padding: 4px 0;
      &:after {
        content: 'M1';
      }
      > div {
        width: 12.5%;
        border: 1px solid rgba(0, 0, 0, 0.24);
        padding: 6px 4px;
        border-right: none;
        &:last-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
    &:nth-of-type(5) {
      background-color: rgba(0, 0, 0, 0.04);
      &:after {
        content: 'Base';
      }
      > div {
        width: 6.25%;
        font-size: 11px;
        padding: 6px 4px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-right: none;
        &:last-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
  }
`;
