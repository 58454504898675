import styled from 'styled-components'

export const Main = styled.main`
  margin: 0 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  h3 {
    width: 100%;
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
    color: #404040;
  }
  > ul {
    display: flex;
    margin-bottom: 40px;
    li {
      list-style-type: none;
      margin-right: 12px;
      cursor: pointer;
      &.disabled {
        > a {
          text-decoration: none;
          cursor: default;
          opacity: 0.4;
        }
      }
      &.selected {
        > a {
          text-decoration: none;
          cursor: default;
          color: #c23da8;
        }
      }
    }
  }
`

export const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  > div {
    width: 50%;
    @media (min-width: 980px) {
      width: 25%;
    }
    margin-bottom: 30px;
  }
`
